import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

const sentryScope = new Sentry.Scope()
sentryScope.setTag('section', 'Document Progress')

export default class extends Controller {
  connect() {
    let interval = setInterval(() => {
      const dataset = this.element.dataset
      let progress = dataset.progress

      this.refreshDocument(dataset.documentId)
        .then(refreshedDocument => {
          let updated_progress = refreshedDocument.data.attributes.progress
          console.log('Progress', updated_progress)
          if (updated_progress === progress) return

          this.reload(dataset.documentId)

          if (['complete', 'failed'].includes(updated_progress)) clearInterval(interval)
        })
        .catch(error => {
          Sentry.captureException(error, sentryScope)
        })
    }, 1000)
  }

  refreshDocument(documentId) {
    return new Promise((resolve, reject) => {
      fetch(`/api/v1/documents/${documentId}`)
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject(error))
    })
  }

  reload(documentId) {
    const target = document.getElementById(`document_${documentId}`)
    target.src = null
    target.src = this.element.dataset.targetSrc
  }
}
