import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {

  changeTabs(e){
    const tabs = Array.from(this.element.querySelectorAll("[role='tab']"))
    const firstTab = tabs[0]
    const lastTab = tabs[tabs.length - 1]

    // go to to previous tab
    if ((e.key === 'ArrowLeft') || (e.key === 'ArrowUp')){
      e.preventDefault()
      if (document.activeElement == firstTab){
        lastTab.focus()
      } else {
        firstTab.focus()
      }
    // go to next tab
    } else if ((e.key === 'ArrowRight') || (e.key === 'ArrowDown')){
      e.preventDefault()
      if (document.activeElement == lastTab){
        firstTab.focus()
      } else {
        lastTab.focus()
      } 
    }
  }
}
