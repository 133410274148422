import { Controller } from '@hotwired/stimulus'
import { mixpanel } from '../application'

// Connects to data-controller="mixpanel-events"
export default class extends Controller {
  static values = {
    eventName: String,
    userId: Number
  }
  // This will work for most events but will not work for links which are followed. See mixpanel.track_links for that
  track() {
    mixpanel.track(this.eventNameValue, { 'distinct_id': this.userIdValue });
  }
}
