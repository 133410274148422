import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-paper"
export default class extends Controller {
  static targets =['cancelbtn', 'clicktoclose']

  openDeletePaperModal(e){
    const deletePaperModal = document.getElementById('delete-paper-modal')
    deletePaperModal.style.display = 'flex'
    const documentId = e.currentTarget.dataset.documentId
    let deleteDocumentUrl = '/hide_document?document_id=' + documentId
    let deletePaperLink = document.getElementById('delete-paper-link')
    deletePaperLink.href = deleteDocumentUrl
    // this.element.classList.add('hidden')
    this.addFocusToFirstModalElement(deletePaperModal)
  }

  closeDeletePaperModal(e){
    const deletePaperModal = document.getElementById('delete-paper-modal')
    //closing modal by clicking outside modal or with cancel btn
    if ((e.target === this.clicktocloseTarget) || (e.target === this.cancelbtnTarget)|| (e.key === 'Escape') ){
      deletePaperModal.style.display = 'none'
    } else {
      return
    }

    // return focus to the button that opened the modal
    const deleteBtn = document.querySelector('.delete-paper-index')
    deleteBtn.focus()
  }

  // adding focus to the first element when modal opens
  addFocusToFirstModalElement(modal){
    const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    focusableElements[0].focus()
  }
}
