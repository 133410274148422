import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="first-upload"
export default class extends Controller {
  static targets = [
    'initialHeader',
    'substituteHeader',
    'pdfButtonsWrapper',
    'fakeFileInputWrapper',
    'fakeFileInput',
    'realFileInput',
    'voiceAndLangWrapper',
    'languageSelect',
    'voiceWrapper',
    'realVoiceInput',
    'voiceList',
    'checkIcon',
    'submitWrapper',
    'submit'
  ]

  connect() {
    this.setInitialVisibilities()

    this.refreshVoices()

    this.realFileInputTarget.addEventListener('change', _event => {
      this.handleFileSelection()
    })
  }

  setInitialVisibilities() {
    this.toggleTargets([
      this.fakeFileInputWrapperTarget,
      this.realFileInputTarget,
      this.voiceAndLangWrapperTarget,
      this.submitWrapperTarget
    ])

    this.fakeFileInputTarget.focus()
  }

  simulateFileInputClick() {
    this.realFileInputTarget.click()
  }

  handleFileSelection() {
    this.toggleTargets([
      this.pdfButtonsWrapperTarget,
      this.initialHeaderTarget,
      this.substituteHeaderTarget,
      this.voiceAndLangWrapperTarget,
      this.voiceWrapperTarget,
      this.submitWrapperTarget
    ])
  }

  handleSubmit(event) {
    document.querySelector('.loading-section').classList.remove('hidden-important');
    document.querySelector('.first-upload-section').classList.add('hidden-important');
  }

  refreshVoices() {
    this.submitTarget.disabled = true

    const currentLanguage = this.languageSelectTarget.value

    this.realVoiceInputTarget.value = null

    // Hide the selected voice check
    this.checkIconTargets.forEach(checkIcon => checkIcon.classList.add('hidden'))

    this.voiceListTargets.forEach(target => {
      if (target.dataset.language === currentLanguage)
        target.classList.remove('hidden')
      else {
        target.classList.add('hidden')
      }
    })
  }

  selectVoice(event) {
    this.realVoiceInputTarget.value = event.currentTarget.dataset.voiceId
    this.submitTarget.disabled = false

    this.checkIconTargets.forEach(checkIcon => {
      if (event.currentTarget.contains(checkIcon))
        checkIcon.classList.remove('hidden')
      else {
        checkIcon.classList.add('hidden')
      }
    })
  }

  toggleTargets(targets) {
    targets.forEach(target => target.classList.toggle('hidden'))
  }
}
