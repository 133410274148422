import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-focus-to-modal"
export default class extends Controller {
  addFocus(e){
    this.checkKey(e, this.element)
  }

  // trap focus inside of modal
  checkKey(e, element){
    const focusableElements = element.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
    console.log(focusableElements)
    const firstFocusableElement = focusableElements[0]
    const lastFocusableElement = focusableElements[focusableElements.length - 1]

    if (e.key === 'Tab' && !e.shiftKey) {
      if (document.activeElement === lastFocusableElement) {
        e.preventDefault()
        firstFocusableElement.focus()
      }
    } else if (e.key === 'Tab' && e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        e.preventDefault()
        lastFocusableElement.focus()
      }
    } else if (e.key !== 'Tab'){
      return
    }
  }
}
