import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['box']

  connect() {
    document.addEventListener('turbo:click', event => {
      const triggerData = event.target.dataset
      if (triggerData.turboFrame !== 'moudl-content') return

      this.applyColorScheme(triggerData.moudlColorScheme)
    })
  }

  close() {
    this.element.classList.add('hidden')
  }

  open() {
    this.element.classList.remove('hidden')
    this.addFocusToFirstModalElement(this.element)
  }

  // adding focus to the first element when modal opens
  addFocusToFirstModalElement(modal){
    const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
    focusableElements[0].focus()
  }

  applyColorScheme(colorScheme) {
    const boxData = this.boxTarget.dataset
    const baseClassList = boxData.baseClassList
    let colorClassList = ''

    if (colorScheme === 'light') {
      colorClassList = boxData.lightClassList
    } else {
      colorClassList = boxData.darkClassList
    }

    this.boxTarget.classList = `${baseClassList} ${colorClassList}`
  }
}
