import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="questionnaire"
export default class extends Controller {
  static values = {
    personaRevealed: true,
    personaFilled: false,
    institutionRevealed: true,
    institutionFilled: false,
    disciplineRevealed: true,
    disciplineFilled: false,
    intentionRevealed: true,
    intentionFilled: false,
    impedimentRevealed: true,
    impedimentFilled: false,
    submitButtonRevealed: true,
  }

  static targets = [
    'personaField',
    'personaInput',
    'institutionField',
    'institutionInput',
    'institutionPencil',
    'disciplineField',
    'disciplineInput',
    'intentionField',
    'intentionInput',
    'impedimentField',
    'impedimentInput',
    'submitButton',
  ]

  connect() {
    this.hideInstitutionField()
    this.hideDisciplineField()
    this.hideIntentionField()
    this.hideImpedimentField()
    this.toggleSubmitButton()

    this.formData = new FormData(this.element)

    const eventTypes = ['click', 'change']

    eventTypes.forEach(eventType => {
      this.element.addEventListener(eventType, () => {
        this.formData = new FormData(this.element)

        this.computePersonaFilledValue()
        this.computeInstitutionFilledValue()
        this.computeDisciplineFilledValue()
        this.computeIntentionFilledValue()
        this.computeImpedimentFilledValue()

        this.toggleInstitutionField()
        this.revealDisciplineField()
        this.revealIntentionField()
        this.revealImpedimentField()
        this.toggleSubmitButton()
      })
    })
  }

  hideInstitutionField() {
    this.institutionFieldTarget.classList.add('hidden')
    this.institutionRevealedValue = false
  }

  showPopup(){
    // render University Pilot Program popup
    document.getElementById('university-step-popup').classList.remove('hidden');
  }

  hidePopup(){
    document.getElementById('university-step-popup').classList.add('hidden');
  }

  hideDisciplineField() {
    this.disciplineFieldTarget.classList.add('hidden')
    this.disciplineRevealedValue = false
  }

  hideIntentionField() {
    this.intentionFieldTarget.classList.add('hidden')
    this.intentionRevealedValue = false
  }

  hideImpedimentField() {
    this.impedimentFieldTarget.classList.add('hidden')
    this.impedimentRevealedValue = false
  }

  toggleSubmitButton() {
    if (
      this.personaFilledValue &&
      this.institutionIsFilledOrHidden() &&
      this.disciplineFilledValue &&
      this.intentionFilledValue &&
      this.impedimentFilledValue
    ) {
      // Return if already revealed, because a user may be trying to focus a
      // different element
      if (this.submitButtonRevealedValue) return

      this.submitButtonTarget.classList.remove('hidden')
      this.submitButtonRevealedValue = true
      this.submitButtonTarget.focus()
    } else {
      this.submitButtonTarget.classList.add('hidden')
      this.submitButtonRevealedValue = false
    }
  }

  toggleInstitutionField() {
    if (!this.personaFilledValue) return

    const institutionlessPersonas = this.institutionFieldTarget.dataset.institutionlessPersonas.split(',')
    const selectedPersona = this.formData.get('user[persona]')

    if (institutionlessPersonas.includes(selectedPersona)) {
      this.institutionFieldTarget.classList.add('hidden')
      this.institutionInputTarget.value = null
      this.institutionRevealedValue = false
      this.institutionFilledValue = false
    } else {
      if (this.institutionRevealedValue) return

      this.institutionFieldTarget.classList.remove('hidden')
      this.institutionRevealedValue = true
      this.institutionInputTarget.focus()
    }
  }

  revealDisciplineField() {
    if (this.disciplineRevealedValue) return

    if (this.personaFilledValue && this.institutionIsFilledOrHidden()) {
      this.disciplineFieldTarget.classList.remove('hidden')
      this.disciplineRevealedValue = true
      this.disciplineInputTarget.focus()
    }
  }

  revealIntentionField() {
    if (this.intentionRevealedValue) return

    if (this.disciplineFilledValue) {
      this.intentionFieldTarget.classList.remove('hidden')
      this.intentionRevealedValue = true
      this.intentionInputTarget.focus()
    }
  }

  revealImpedimentField() {
    if (this.impedimentRevealedValue) return

    if (this.intentionFilledValue) {
      this.impedimentFieldTarget.classList.remove('hidden')
      this.impedimentRevealedValue = true
      this.impedimentInputTarget.focus()
    }
  }

  computePersonaFilledValue() {
    const selectedPersona = this.formData.get('user[persona]')
    const typedPersona = this.formData.get('user[custom_persona]')

    this.personaFilledValue = selectedPersona || typedPersona ? true : false
  }

  computeInstitutionFilledValue() {
    const typedInstitution = this.formData.get('user[claimed_institution]')

    this.institutionFilledValue = typedInstitution ? true : false
  }

  institutionIsFilledOrHidden() {
    if (this.institutionFilledValue) return true

    return this.institutionRevealedValue ? false : true
  }

  computeDisciplineFilledValue() {
    const selectedDiscipline = this.formData.get('user[discipline]')
    const typedDiscipline = this.formData.get('user[custom_discipline]')

    this.disciplineFilledValue = selectedDiscipline || typedDiscipline ? true : false
  }

  computeIntentionFilledValue() {
    const selectedIntention = this.formData.get('user[intention]')
    const typedIntention = this.formData.get('user[custom_intention]')

    this.intentionFilledValue = selectedIntention || typedIntention ? true : false
  }

  computeImpedimentFilledValue() {
    const selectedImpediment = this.formData.get('user[impediment]')
    const typedImpediment = this.formData.get('user[custom_impediment]')

    this.impedimentFilledValue = selectedImpediment || typedImpediment ? true : false
  }

  handleInstitutionBlur() {
    this.institutionInputTarget.classList.remove('border-b-teal-400')
    // this.institutionPencilTarget.classList.remove('hidden')
  }

  focusInstitution() {
    this.institutionInputTarget.focus()
  }

  handleInstitutionInput() {
    this.resizeInstitutionInput()
    this.blurInstitutionWhenDoneTyping()
  }

  resizeInstitutionInput() {
    const target = this.institutionInputTarget

    target.size = Math.max(4, target.value.length)
  }

  blurInstitutionWhenDoneTyping() {
    if (this.institutionTimeout) {
      clearTimeout(this.institutionTimeout)
    }

    this.institutionTimeout = setTimeout(() => {
      this.element.click()
    }, 1500)
  }

  institutionQuickSelect(event) {
    this.preventFormSubmission(event)
    this.element.click()
  }

  // This is needed because if you press TAB during normal flow (i.e. later
  // fields not yet visible) there's nothing to tab to, and we lose focus
  handleInstitutionTabPress(event) {
    if (this.disciplineRevealedValue) return

    this.institutionQuickSelect(event)
  }

  preventFormSubmission(event) {
    event.preventDefault()
  }
}
