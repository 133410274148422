import { Controller } from "@hotwired/stimulus"
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';

// Connects to data-controller="show-left-bar"
export default class extends Controller {
  static targets = ["bar", "headers", "rightbtn", "div"]

  connect() {
    // check to see if user is on an open access paper
    let onOpenAccess = window.location.pathname.startsWith('/open_access_paper')

    async function postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      return response
    }
    let documentId = parseInt(this.barTarget.dataset.id)

    Sortable.mount(new AutoScroll())
    //if mobile, disable the sorting for the non-menu page
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      new Sortable(document.getElementById('sortable-list'), {
        draggable:".draggable-item",
        scroll: true,
        bubbleScroll: true,
        forceAutoscrollFallback: true,
        scrollSensitivity: 200,

        onUpdate: function (event) {
          if (!onOpenAccess){
            postData(`${process.env.BASE_URL}/api/v1/change_section_order`, { document: { id: documentId, oldIndex: event.oldIndex , newIndex: event.newIndex } })
              .then((response) => {
                console.log(response.status)
              })
              .catch((e) => {
                console.log(e)
              })
          // if the user is on open access paper, do NOT allow API call to change section order
          } else {
            return
          }
        }
      })
    } else {
    // for desktop no need to disable sorting
      new Sortable(document.getElementById('sortable-list'), {
        scroll: true,
        bubbleScroll: true,
        scrollSensitivity: 50,

        onUpdate: function (event) {
          if (!onOpenAccess){
            postData(`${process.env.BASE_URL}/api/v1/change_section_order`, { document: { id: documentId, oldIndex: event.oldIndex , newIndex: event.newIndex } })
              .then((response) => {
                console.log(response.status)
              })
              .catch((e) => {
                console.log(e)
              })
          // if the user is on open access paper, do NOT allow API call to change section order
          } else {
            return
          }
        }
      })
    }
  }

  open(){
    this.barTarget.classList.add("left-bar-mobile")
    this.headersTarget.classList.add('vertical-options')
    this.rightbtnTarget.classList.add('none')
    this.divTarget.classList.add('sortable-scroll')

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      Array.from(this.headersTarget.children).forEach(element => element.classList.add('draggable-item'))
    }
  }

  close(){
    this.barTarget.classList.remove("left-bar-mobile")
    this.headersTarget.classList.remove('vertical-options')
    this.rightbtnTarget.classList.remove('none')
    this.divTarget.classList.remove('sortable-scroll')

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      Array.from(this.headersTarget.children).forEach(element => element.classList.remove('draggable-item'))
    }
  }
}
