import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="uploading"
export default class extends Controller {
  static targets = ['successMessage', 'failureMessage']

  connect() {
    document.addEventListener('turbo:frame-load', this.handleDataChange.bind(this))
  }

  handleDataChange(event) {
    // Ignore turbo frame events elsewhere in the document
    if (this.element.contains(event.target) === false) return

    this.showCallToAction()
  }

  showCallToAction() {
    const currentProgress = this.element.dataset.progress

    if (currentProgress === 'complete') {
      this.successMessageTarget.classList.remove('hidden')
    } else if (currentProgress === 'failed') {
      this.failureMessageTarget.classList.remove('hidden')
    }
  }
}
