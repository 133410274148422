import { Controller } from '@hotwired/stimulus'

window.slideshowIndex = 0

// Connects to data-controller="slideshow"
export default class extends Controller {
  static targets = ['slides', 'dots']

  next(){
    if (window.slideshowIndex === 3){
      window.slideshowIndex = 0
    } else {
      window.slideshowIndex++
    }
    this.changeSlide()
  }

  back(){
    if (window.slideshowIndex === 0){
      window.slideshowIndex = 3
    } else {
      window.slideshowIndex--
    }
    this.changeSlide()

  }

  changeSlide(){
    const slides = Array.from(this.slidesTarget.children)
    const dots = Array.from(this.dotsTarget.children)

    for (let i = 0; i < dots.length; i++) {
      slides[i].classList.add('hidden')
      dots[i].classList.remove('active')
    }

    dots[window.slideshowIndex].classList.add('active')
    slides[window.slideshowIndex].classList.remove('hidden')
  }
}
