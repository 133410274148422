export default class ReadAlongVisibilityHandler {
  static async refreshReadAlongForSection(documentId, sectionId, operation) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const data = { operation };

    try {
      const response = await fetch(`/documents/${documentId}/sections/${sectionId}/refresh_read_along`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Network Error');
      }

      const responseData = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
