import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="popup-survey"
export default class extends Controller {

  connect() {
    window.TallyConfig = {
      formId: 'mDqzvj',
      popup: {
        emoji: {
          text: '👋',
          animation: 'wave',
        },
        autoClose: 0,
        open: {
          trigger: 'time',
          ms: 240000,
        },
        hideTitle: true,
        overlay: true,
        showOnce: true,
      },
    }
  }
}
