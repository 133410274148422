import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="trial-plan"
export default class extends Controller {
  static targets = [
    'displayPrice',
    'yearlyButton',
    'monthlyButton',
    'quarterlyButton',
    'yearlyQuota',
    'quarterlyQuota',
    'monthlyQuota'
  ]

  connect() {
    const planType = document.getElementById('plan_type');
    if (planType && planType.value) {
      switch (planType.value) {
        case 'monthly':
          this.goMonthly();
          break;
        case 'quarterly':
          this.goQuarterly();
          break;
        default:
          this.goYearly();
          break;
      }
    } else {
      this.goYearly();
    }
  }  
  
  goYearly() {
    this.yearlyButtonTarget.click()
    this.monthlyQuotaTarget.classList.add('hidden')
    this.quarterlyQuotaTarget.classList.add('hidden')
    this.yearlyQuotaTarget.classList.remove('hidden')

    const priceElement = this.displayPriceTarget
    priceElement.innerText = priceElement.dataset.yearlyPrice
  }

  goMonthly() {
    this.monthlyButtonTarget.click()
    this.monthlyQuotaTarget.classList.remove('hidden')
    this.yearlyQuotaTarget.classList.add('hidden')
    this.quarterlyQuotaTarget.classList.add('hidden')

    const priceElement = this.displayPriceTarget
    priceElement.innerText = priceElement.dataset.monthlyPrice
  }

  goQuarterly() {
    this.quarterlyButtonTarget.click()
    this.quarterlyQuotaTarget.classList.remove('hidden')
    this.yearlyQuotaTarget.classList.add('hidden')
    this.monthlyQuotaTarget.classList.add('hidden')

    const priceElement = this.displayPriceTarget
    priceElement.innerText = priceElement.dataset.quarterlyPrice
  }
}
