import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="elsevier-form"
export default class extends Controller {
  connect() {
    setTimeout(function() {
      Tally.openPopup('w8aGgl', {
        width: 500,
        emoji: {
          text: '👋',
          animation: 'wave'
        },
        overlay: true,
        showOnce: true,
      })
    }, 30000) // 30-second delay
  }
}
