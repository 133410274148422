import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="new-document"
export default class extends Controller {
  tokenClient = null; pickerInited = false; gisInited = false; gdriveFile = null
  static values = {
    url: String,
    opened: Boolean,
    defaultLanguage: String
  }

  static targets = [
    'overlay', 'dropdown', 'overlayUpload', 'overlayTranslation', 'spanish', 'english', 'gdriveFile',
    'formInputs', 'form', 'formMobile', 'zoteroOverlay', 'gdriveOverlay', 'error',
    'fileLoader', 'loadingAnimation', 'importPdfModal', 'button', 'newpaperbtn',
    'newDocument', 'fileInput', 'settingsMenu', 'languageMenu', 'voicesMenu', 'englishVoices',
    'spanishVoices', 'languageSelection', 'voiceSelection', 'fileName', 'filePicker', 'authButton'
  ]

  connect() {
    // Load Google API and Google Identity Services
    this.loadGoogleAPI()
    this.loadGIS()

    // Load the access token from local storage
    this.savedAccessToken = JSON.parse(localStorage.getItem('gdrive_access_token'))
    this.accessToken = this.savedAccessToken ? this.savedAccessToken.value : null
    this.updateButtons()
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle('hidden')
  }

  closeDropdown() {
    this.dropdownTarget.classList.add('hidden')
    this.newpaperbtnTarget.focus()
  }

  openUploadOverlay(needsFile = true, importZotero = false) {
    const desktopQuery = window.matchMedia('(min-width: 980px)')
    // if desktop, open 'choose file' modal
    if (desktopQuery.matches) {
      this.overlayTarget.style.display = 'flex'
      this.toggleDropdown()
      if (needsFile || importZotero) this.addFocusToFirstModalElement(this.overlayUploadTarget)
    } else {
      // if mobile, skip the 'choose file' modal and directly open the choose file button
      this.newDocumentTarget.dataset.mobile = 'true'
      if (needsFile) this.fileInputTarget.querySelector('input').click()

    }
  }

  closeUploadOverlay() {
    this.overlayTarget.style.display = 'none'
  }

  goToTranslation() {
    if (this.newDocumentTarget.dataset.mobile === 'true') {
      this.overlayTarget.style.display = 'flex'
      document.querySelector('.document_index').style.overflow = 'hidden'
    }
    this.overlayUploadTarget.classList.add('hidden')
    this.overlayTranslationTarget.classList.remove('hidden')
  }

  chooseSpanish() {
    // if the default voice is English and the user changes to Spanish, set Manuel as the default
    if (this.defaultLanguageValue === 'EN') {
      this.voiceSelectionTarget.innerText = 'Manuel'
      this.formInputsTarget.querySelector('#document_voice_id').value = 5
      const buttons = this.spanishVoicesTarget.querySelectorAll('button')
      buttons.forEach(button =>
        button.classList.remove('active')
      )
      buttons[0].classList.add('active')
    }

    this.spanishTarget.classList.add('selected-language')
    this.englishTarget.classList.remove('selected-language')

    // Hide/show svg
    this.spanishTarget.children[0].classList.remove('hidden')
    this.englishTarget.children[0].classList.add('hidden')

    this.languageSelectionTarget.innerText = 'Spanish'
  }

  chooseEnglish() {
    // if the default voice is Spanish and the user changes to English, set Oliver as the default
    if (this.defaultLanguageValue === 'ES') {
      this.voiceSelectionTarget.innerText = 'Oliver'
      this.formInputsTarget.querySelector('#document_voice_id').value = 1
      const buttons = this.englishVoicesTarget.querySelectorAll('button')
      buttons.forEach(button =>
        button.classList.remove('active')
      )
      buttons[0].classList.add('active')
    }

    this.englishTarget.classList.add('selected-language')
    this.spanishTarget.classList.remove('selected-language')

    // Hide/show svg
    this.englishTarget.children[0].classList.remove('hidden')
    this.spanishTarget.children[0].classList.add('hidden')

    this.languageSelectionTarget.innerText = 'English'
  }

  chooseEnglishVoice(event) {
    const buttons = this.englishVoicesTarget.querySelectorAll('button')
    // active class includes white text, displaying svg check, displaying svg blue ring
    buttons.forEach(button =>
      button.classList.remove('active')
    )
    event.currentTarget.classList.add('active')

    // change the name displayed on main settings menu
    const voice = event.currentTarget.querySelector('p').innerText
    this.voiceSelectionTarget.innerText = voice
    // change the language displayed on main settings menu
    this.languageSelectionTarget.innerText = 'English'

    // fill the form for the polly api call
    const id = event.currentTarget.dataset.id
    this.formInputsTarget.querySelector('#document_voice_id').value = id
  }

  chooseSpanishVoice(event) {
    const buttons = this.spanishVoicesTarget.querySelectorAll('button')
    // active class includes white text, displaying svg check, displaying svg blue ring
    buttons.forEach(button =>
      button.classList.remove('active')
    )
    event.currentTarget.classList.add('active')

    // change the name displayed on main settings menu
    const voice = event.currentTarget.querySelector('p').innerText
    this.voiceSelectionTarget.innerText = voice
    // change the language displayed on main settings menu
    this.languageSelectionTarget.innerText = 'Spanish'

    // fill the form for the polly api call
    const id = event.currentTarget.dataset.id
    this.formInputsTarget.querySelector('#document_voice_id').value = id
  }

  submitForm(e) {
    e.preventDefault()
    e.target.innerText = 'Loading...'

    if (this.gdriveFile !== null && this.gdriveFile.blob) {
      const formData = new FormData(this.formTarget)
      formData.append('document[academic_paper]', this.gdriveFile.blob)

      fetch(this.formTarget.action, {
        method: this.formTarget.method,
        body: formData,
        redirect: 'follow'
      })
        .then(response => {
          if (response.ok && response.redirected) {
            window.location.href = response.url
          }
        })
        .catch(error => {
          console.error('Error:', error.message)
        })
    } else {
      this.formTarget.submit()
    }

    document.querySelector('.document_index').style.overflow = ''
  }

  openZoteroOverlay() {
    this.toggleDropdown()
    this.zoteroOverlayTarget.classList.remove('hidden')
    this.addFocusToFirstModalElement(this.zoteroOverlayTarget)

    // This code verifies if the overlay has been opened before
    // It also checks for the file loader target, if it is not present the user has not logged in to Zotero
    if (this.openedValue === false && this.hasFileLoaderTarget) {
      fetch(this.urlValue)
        .then(response => response.text())
        .then((html) => {
          // Hide loading animation
          this.loadingAnimationTarget.classList.add('hidden')
          this.fileLoaderTarget.insertAdjacentHTML('afterbegin', html)
        })
    }
    this.openedValue = true
  }

  closeZoteroOverlay() {
    this.zoteroOverlayTarget.classList.add('hidden')
  }

  openGdriveOverlay() {
    this.updateButtons()
    this.toggleDropdown()
    this.gdriveOverlayTarget.classList.remove('hidden')
    this.addFocusToFirstModalElement(this.gdriveOverlayTarget)
  }

  closeGdriveOverlay() {
    this.gdriveOverlayTarget.classList.add('hidden')
  }

  chooseDocumentZotero(e) {
    // Basically I need to get this url and fill it in the form
    const key = e.currentTarget.parentElement.dataset.selectedKey
    const groupId = e.currentTarget.parentElement.dataset.groupId
    this.formInputsTarget.insertAdjacentHTML('beforeend', `
    <div class="mb-4 url optional document_zotero_key">
      <input class="string url optional" value="${key}" type="url" name="document[zotero_key]" id="document_zotero_key">
    </div>`)

    if (groupId !== '') {
      this.formInputsTarget.insertAdjacentHTML('beforeend', `
      <div class="mb-4 url optional document_zotero_group_id">
        <input class="string url optional" value="${groupId}" type="url" name="document[zotero_group_id]" id="document_zotero_group_id">
      </div>`)
    }

    this.closeZoteroOverlay()
    this.toggleDropdown()
    this.openUploadOverlay(false, true)
    this.goToTranslation()
  }

  goToLanguage() {
    this.settingsMenuTarget.classList.add('hidden')
    this.languageMenuTarget.classList.remove('hidden')
  }

  goToVoices() {
    this.settingsMenuTarget.classList.add('hidden')
    this.voicesMenuTarget.classList.remove('hidden')

    // show correct voices based on language selection
    if (this.englishTarget.classList.contains('selected-language')) {
      this.englishVoicesTarget.classList.remove('hidden')
      this.spanishVoicesTarget.classList.add('hidden')
    } else {
      this.spanishVoicesTarget.classList.remove('hidden')
      this.englishVoicesTarget.classList.add('hidden')
    }
  }

  goBackToSettings() {
    this.settingsMenuTarget.classList.remove('hidden')
    this.voicesMenuTarget.classList.add('hidden')
    this.languageMenuTarget.classList.add('hidden')
  }

  // open choose file button with keyboard enter
  onEnter() {
    this.buttonTarget.click()
  }

  // adding focus to the first element when modal opens
  addFocusToFirstModalElement(modal) {
    const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
    focusableElements[0].focus()
  }

  //  load and Initialize Google file picker
  loadGoogleAPI() {
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.async = true
    script.defer = true
    script.onload = () => {
      this.gapiLoaded()
    }
    document.body.appendChild(script)
  }

  loadGIS() {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = () => {
      this.gisLoaded()
    }
    document.body.appendChild(script)
  }

  gapiLoaded() {
    gapi.load('client:picker', () => {
      this.initializePicker()
    })
  }

  gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: process.env.GPICKER_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive.readonly',
      callback: '', // defined later
    })
    this.gisInited = true
  }

  initializePicker() {
    gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest').then(() => {
      this.pickerInited = true
    })
  }

  updateButtons() {
    // change text of buttons if already logged
    if (!this.hasAuthButtonTarget) return

    if (!this.tokenExpired()) {
      this.authButtonTarget.textContent = this.authButtonTarget.dataset.selectValue
    }
  }

  handleAuthClick() {
    // Open the file picker
    this.errorTarget.textContent = '' // clear any previous error messages
    this.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw (response)
      }
      this.accessToken = response.access_token
      this.saveToken(response)
      await this.createPicker()
    }

    if (this.tokenExpired()) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new & expired session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' })
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' })
    }

  }


  saveToken(resp) {
    // persist the token in local storage (1hr)
    const expTime = new Date().getTime() + (resp.expires_in * 1000)
    this.savedAccessToken = {
      value: resp.access_token,
      expirationTime: expTime
    }
    localStorage.setItem('gdrive_access_token', JSON.stringify(this.savedAccessToken))
  }

  tokenExpired() {
    // purge expired login token
    if (this.savedAccessToken == null || this.accessToken === null) return true

    const currentTime = new Date().getTime()
    if (this.savedAccessToken.expirationTime < currentTime) {
      localStorage.removeItem('gdrive_access_token')
      return true
    }

    return false
  }

  async createPicker() {
    const view = new google.picker.View(google.picker.ViewId.DOCS)
    view.setMimeTypes('application/pdf')
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(process.env.GPICKER_API_KEY)
      .setAppId(process.env.GPICKER_APP_ID)
      .setOAuthToken(this.accessToken)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback((data) => {
        this.pickerCallback(data)
      })
      .build()
    picker.setVisible(true)
  }

  async pickerCallback(data) {
    // Proceed when file is selected
    if (data.action === google.picker.Action.PICKED) {
      const doc = data[google.picker.Response.DOCUMENTS][0]
      const fileSize = doc.sizeBytes

      if (fileSize > 10 * 1024 * 1024) { // 10MB limit for gdrive import 
        this.errorTarget.textContent = 'Selected file exceeds the maximum allowed size of 10MB.'
        setTimeout(() => { this.errorTarget.textContent = '' }, 4000)
        return
      }
      this.gdriveFile = {
        id: doc[google.picker.Document.ID],
        name: doc[google.picker.Document.name],
        blob: null
      }

      this.authButtonTarget.textContent = 'Importing from Drive'
      await this.fetchDriveFile()

      document.getElementById('formInputs').insertAdjacentHTML('beforeend', `
      <div class="mb-4 optional document_gdrive_file_id">
        <input class="string optional" value="${this.gdriveFile.id}" type="text" name="document[gdrive_file_id]" id="document_gdrive_file_id">
      </div>`)

      this.closeGdriveOverlay()
      this.toggleDropdown()
      this.openUploadOverlay(false)
      this.goToTranslation()
    }
  }

  async fetchDriveFile() {
    await fetch(`https://www.googleapis.com/drive/v3/files/${this.gdriveFile.id}?alt=media`, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        this.gdriveFile.blob = new File([blob], this.gdriveFile.name, { type: 'application/pdf' })
      })
  }

}