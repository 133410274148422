import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="simple-modal"
// used for subscription modal which only has one button
export default class extends Controller {
  static targets = ['modal', 'btn','overlay']

  connect(){
    document.body.style.overflow = ('hidden')
    this.btnTarget.focus()
  }

  handleClose(e){
    document.body.style.overflow = ('scroll')
    if (e.target === this.overlayTarget || e.target === this.btnTarget){
      this.overlayTarget.style.display = 'none'
    }
  }
}
