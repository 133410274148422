import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="fixed-header"
export default class extends Controller {
  connect() {
    this.prevScrollPos = window.scrollY
    this.scrollThreshold = 100
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    const currentScrollPos = window.scrollY
    const scrollDifference = Math.abs(this.prevScrollPos - currentScrollPos)

    if (scrollDifference < this.scrollThreshold) return

    if (currentScrollPos < this.prevScrollPos) {
      this.element.classList.remove('hidden')
    } else {
      this.element.classList.add('hidden')
    }

    this.prevScrollPos = currentScrollPos
  }
}
