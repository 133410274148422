import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'expandButton', 'collapseButton', 'content', 'mobileContent'
  ]

  toggle() {
    this.contentTarget.classList.toggle('hidden')
    this.mobileContentTarget.classList.toggle('hidden')
    this.expandButtonTarget.classList.toggle('hidden')
    this.collapseButtonTarget.classList.toggle('hidden')
  }
}
