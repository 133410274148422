import { Controller } from "@hotwired/stimulus"
let audio
// Connects to data-controller="voices-audio-player"
export default class extends Controller {

  playAudio(event){
    this.pauseAudio() // Pause any currently playing
    audio = new Audio()
    audio.src = `https://assets-for-audemic-mvp-feb-25.s3.eu-west-2.amazonaws.com/tts_voice_samples/${event.currentTarget.dataset.voice}.mp3`
    audio.play()
  }

  pauseAudio() {
    // If audio is not undefined and if is playing, pause it
    if (audio && !audio.paused) {
      audio.pause()
      audio.currentTime = 0 // Rewind track to beginning
    }
  }
}
