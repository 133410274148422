const awarenessNotes = () => {
  let awarenessNote = document.getElementById("awn");
  let awarenessBox1 = document.getElementById("awb-1");
  let awarenessBox2 = document.getElementById("awb-2");
  let awarenessClosings = document.getElementsByClassName("an-close-skip");
  let awarenessContent = document.getElementsByClassName("an-take-cont");

  if (awarenessNote){
    for (let i = 0; i < awarenessClosings.length; i++) {
      awarenessClosings[i].addEventListener('click', () => hideAwarenessNote());
    }

    awarenessBox1.addEventListener('swiped-right', function (e) {
      changeBox1();
    });

    awarenessBox2.addEventListener('swiped-left', function (e) {
      changeBox2();
    });


    function hideAwarenessNote() {
      awarenessNote.classList.add('hidden');
    }

    function changeBox1() {
      awarenessBox1.classList.add('hidden');
      awarenessBox2.classList.remove('hidden');
    }

    function changeBox2() {
      awarenessBox2.classList.add('hidden');
      awarenessBox1.classList.remove('hidden');
    }

  }
}

export { awarenessNotes };
