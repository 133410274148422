import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

// Connects to data-controller="get-ip"
export default class extends Controller {
  static targets = ['ip']
  connect() {
    fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(data => this.ipTarget.value = data.ip)
      .catch(error => Sentry.captureMessage(error))
  }
}
