import * as Sentry from '@sentry/browser'

const sentryScope = new Sentry.Scope()
sentryScope.setTag('section', 'Highlights')

const textSelect = () => {

  // Desktop highlight outer box
  let box = document.getElementById('find-me')

  // Mobile highlight outer box
  let boxMobile = document.getElementById('mobile-find-me')


  const broadcastMode = Object.prototype.hasOwnProperty.call(document.body.dataset, 'broadcastMode')

  // Exit early if text selector feature is disabled for current paper for
  // various reasons
  if (!box || broadcastMode) return


  // These variables will be used in the post api request to create a highlight
  let highlightContent = null
  let documentID = parseInt(box.dataset.documentId)
  let boardID = null
  let selectionEndTimeout = null

  // DESKTOP ELEMENTS ----------------------------------------------------------

  // Highlight button
  let textBox = document.getElementById('highlight-popup-text')
  // Highlight overlay
  let highlightOverlayDesktop = document.getElementById('highlight-overlay-desktop')
  // Close button
  let closeButtonDesktop = document.getElementById('overlay-close-desktop')
  // Highlighted text
  let overlayHighlightContentDesktop = document.getElementById('overlay-highlight-content-desktop')
  // Div containing boards
  let highlightOverlayBoardsDivDesktop = document.querySelector('.all-boards-highlight-desktop')
  // Board overlay open button
  let newBoardBtnDesktop = document.getElementById('new-board-from-highlight-button-desktop')
  // Board overlay
  let overlayDesktop = document.getElementById('new-board-overlay-from-highlight-desktop')
  // Board overlay close button
  let closeBtnDesktop = document.getElementById('close-new-board-overlay-desktop')
  // Create board input field
  let boardNameInputDesktop = document.getElementById('board-name-input-side')
  // Finale create new board button
  let createBoardBtnDesktop = document.getElementById('final-create-btn-side')

  // ---------------------------------------------------------------------------

  // MOBILE ELEMENTS -----------------------------------------------------------

  // Highlight overlay
  let highlightOverlay = document.getElementById('create-highlight-overlay')
  // Close btn
  let closeButton = document.getElementById('overlay-close')
  // Highlighted text
  let overlayHighlightContent = document.getElementById('overlay-highlight-content')
  // Div containing boards
  let highlightOverlayBoardsDiv = document.querySelector('.all-boards-highlight-mobile')

  // Board overlay open button
  let newBoardBtn = document.getElementById('new-board')
  // Board overlay
  let overlay = document.getElementById('new-board-overlay-from-highlight')
  // Board overlay close button
  let closeBtn = document.getElementById('close-new-board-overlay')
  // Create board input field
  let boardNameInput = document.getElementById('board-name-input')
  // Final create new board button
  let createBoardBtn = document.getElementById('final-create-btn')

  // ---------------------------------------------------------------------------

  // All user boards within div for both mobile and desktop
  let boardNameDiv = document.getElementsByClassName('overlay-board-name-div')

  // Text selection event logic ------------------------------------------------
  // bind selection change event to my function
  document.onselectionchange = userSelectionChanged

  function userSelectionChanged(event) {
    // wait 500 ms after the last selection change event
    if (selectionEndTimeout) {
      clearTimeout(selectionEndTimeout)
    }
    selectionEndTimeout = setTimeout(function () {
      let selEndEvent = new CustomEvent('selectionEnd')
      window.dispatchEvent(selEndEvent)
    }, 500)
    event.preventDefault()
  }

  // function to get the currently selected change
  function getSelectionText() {
    let textSelectionObject = window.getSelection()
    return textSelectionObject
  }

  window.addEventListener('selectionEnd', function (event) {
    // reset selection timeout
    selectionEndTimeout = null

    // get user selection
    let selectedTextObj = getSelectionText()
    let selectedText = selectedTextObj.toString()

    if (selectedText != '') {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        boxMobile.classList.remove('hidden')
      } else {
        box.classList.remove('hidden')
        box.style.top = `${window.getSelection().getRangeAt(0).getBoundingClientRect().top - 55}px`
        box.style.left = `${window.getSelection().getRangeAt(0).getBoundingClientRect().left + window.getSelection().getRangeAt(0).getBoundingClientRect().width / 2 - 55}px`
      }
      // Fill both overlays with highlighted text
      overlayHighlightContent.innerText = selectedText
      overlayHighlightContentDesktop.innerText = selectedText

      // Update highlight var to hold text value
      highlightContent = selectedText
    } else {
      box.classList.add('hidden')
      boxMobile.classList.add('hidden')
    }
    event.preventDefault()
  })

  // ---------------------------------------------------------------------------

  // SHOW AND HIDE HIGHLIGHT OVERLAY EVENT LOGIC -------------------------------

  // check to see if user is on an open access paper
  let onOpenAccess = window.location.pathname.startsWith('/open_access_paper')

  // Mobile highlight overlay show click event
  // don't open highlight modal on open access papers
  if (!onOpenAccess){
    boxMobile.addEventListener('click', () => {
      highlightOverlay.style.height = '100%'
      setTimeout(() => {
        newBoardBtn.classList.remove('hidden')
      }, 400)
    })
  }

  // Desktop highlight overlay show click event
  // don't open highlight modal on open access papers
  if (!onOpenAccess){
    textBox.addEventListener('click', () => {
      highlightOverlayDesktop.style.display = 'flex'
      textBox.focus()
    })
  }

  // Close method for both highlight overlays
  const closeHighlightOverlay = () => {
    highlightOverlayDesktop.style.display = 'none'
    highlightOverlay.style.height = '0'
    newBoardBtn.classList.add('hidden')
  }

  // Mobile close button event
  closeButton.addEventListener('click', () => {
    closeHighlightOverlay()
  })

  // Desktop close button event
  closeButtonDesktop.addEventListener('click', () => {
    closeHighlightOverlay()
  })

  // ---------------------------------------------------------------------------

  // BOARD OVERLAY EVENT LOGIC -------------------------------------------------

  // Mobile board overlay show click event
  newBoardBtn.addEventListener('click', () => {
    overlay.style.height = '100%'
  })
  // Desktop board overlay show click event
  newBoardBtnDesktop.addEventListener('click', () => {
    overlayDesktop.style.display = 'flex'
    overlayDesktop.focus()
  })

  // Close method for both board overlays
  const closeBoardOverlay = () => {
    overlay.style.height = '0'
    overlayDesktop.style.display = 'none'
  }
  // Mobile board overlay hide click event
  closeBtn.addEventListener('click', () => {
    closeBoardOverlay()
  })
  // Desktop board overlay hide click event
  closeBtnDesktop.addEventListener('click', () => {
    closeBoardOverlay()
  })
  // Mobile final create new board button
  createBoardBtn.addEventListener('click', () => {
    let inputText = boardNameInput.value
    boardCreate(inputText)
  })

  // Desktop final create new board button
  createBoardBtnDesktop.addEventListener('click', () => {
    let inputText = boardNameInputDesktop.value
    boardCreate(inputText)
  })

  // ---------------------------------------------------------------------------


  // CREATE HIGHLIGHT AND BOARD WITH API LOGIC ---------------------------------

  // Function used to post (create) board and highlight
  async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    return response
  }

  // Logic to close overlays and show flashes (error or success)
  const highlightSuccess = () => {
    closeHighlightOverlay()
    let successFlash = document.querySelector('.flash-success')
    successFlash.querySelector('.flash-text').innerText = 'Highlight created successfully!'
    successFlash.classList.remove('invisible')
    successFlash.classList.remove('hide-animation')
    setTimeout(() => {
      successFlash.classList.add('hide-animation')
    }, 50)
  }
  const highlightFailure = () => {
    closeHighlightOverlay()
    let errorFlash = document.querySelector('.flash-error')
    errorFlash.querySelector('.flash-text').innerText = 'There was an error :('
    errorFlash.classList.remove('invisible')
    errorFlash.classList.remove('hide-animation')
    setTimeout(() => {
      errorFlash.classList.add('hide-animation')
    }, 50)
  }

  const boardSuccess = (boardData) => {
    // Insert new board into desktop highlight overlay
    highlightOverlayBoardsDivDesktop.insertAdjacentHTML(
      'afterbegin',
      `<div data-board-id="${boardData.id}" class="overlay-board-name-div flex items-center ml-8 mr-5 mt-3 cursor-pointer rounded-md">
        <div class="">
          <img height="30px" width="30px" src="https://assets-for-audemic-mvp-feb-25.s3.eu-west-2.amazonaws.com/board-gradients-small/BoardBoxSmall${boardData.gradient}.png" alt="gradient color box">
        </div>
        <div class="flex-grow pl-3 overlay-board-name">
          ${boardData.name}
        </div>
      </div>`
    )

    // Insert new board into mobile highlight overlay
    highlightOverlayBoardsDiv.insertAdjacentHTML(
      'afterbegin',
      `<div data-board-id="${boardData.id}" class="overlay-board-name-div flex items-center ml-8 mr-5 mt-3 cursor-pointer rounded-md">
          <div class="">
            <img src="https://assets-for-audemic-mvp-feb-25.s3.eu-west-2.amazonaws.com/board-gradients-small/BoardBoxSmall${boardData.gradient}.png" alt="gradient color box">
          </div>
          <div class="flex-grow pl-3 overlay-board-name">
            ${boardData.name}
          </div>
      </div>`
    )

    // Here we have to create a highlight with the board we just made
    postData(`${process.env.BASE_URL}/api/v1/highlights`, { highlight: { content: highlightContent, document_id: documentID, board_id: boardData.id } })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          highlightSuccess()
        } else {
          highlightFailure()
          Sentry.captureMessage(`Error creating a new highlight after text selection with status code: ${response.status}`, sentryScope)
        }
      })
      .catch((e) => {
        highlightFailure()
        Sentry.captureMessage(`Error creating a new highlight after text selection. Error: ${e}`, sentryScope)
      })

    // Clear board overlay input
    boardNameInput.value = ''
    boardNameInputDesktop.value = ''
    // Close board overlays
    closeBoardOverlay()
  }

  const boardFailure = () => {
    // Clear board overlay input
    boardNameInput.value = ''
    boardNameInputDesktop.value = ''
    // Close board overlay and back to highlight overlay
    closeBoardOverlay()
    closeHighlightOverlay()

    let errorFlash = document.querySelector('.flash-error')
    errorFlash.querySelector('.flash-text').innerText = 'There was an error :('
    errorFlash.classList.remove('invisible')
    errorFlash.classList.remove('hide-animation')
    setTimeout(() => {
      errorFlash.classList.add('hide-animation')
    }, 50)
  }

  // Event listeners to create highlight using API post
  for (let board of boardNameDiv) {
    board.addEventListener('click', () => {
      // Select the board within the form
      boardID = board.dataset.boardId

      // Send post request to create highlight
      postData(`${process.env.BASE_URL}/api/v1/highlights`, { highlight: { content: highlightContent, document_id: documentID, board_id: boardID }})
        .then((response) => {
          console.log(response.status)
          if (response.status >= 200 && response.status <= 299){
            highlightSuccess()
          }else{
            highlightFailure()
            Sentry.captureMessage(`Error creating a new highlight after text selection with status code: ${response.status}`, sentryScope)
          }
        })
        .catch((e) => {
          highlightFailure()
          Sentry.captureMessage(`Error creating a new highlight after text selection. Error: ${e}`,sentryScope )
        })
    })
  }

  // Method to create a board using API post
  const boardCreate = (boardName) => {
    // API call to create board
    postData(`${process.env.BASE_URL}/api/v1/boards`, { board: { name: boardName } })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json()
        } else {
          boardFailure()
          Sentry.captureMessage(`Error creating a new board after text selection with status code: ${response.status}`, sentryScope)
        }
      })
      .then((data) => {
        boardSuccess(data)
      })
      .catch((error) => {
        boardFailure()
        Sentry.captureException(`Error creating a new board after text selection with error: ${error}`, sentryScope)
      })
  }
}

export { textSelect }
