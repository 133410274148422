import { Controller } from '@hotwired/stimulus'
import { mixpanel } from '../application'
import WebFont from 'webfontloader'

// Connects to data-controller="document-appearance"
export default class extends Controller {
  static lineSpacingTargets = ['lineSpacingBox', 'lineSpacingSelected', 'Spacing16pxIcon', 'Spacing18pxIcon', 'Spacing20pxIcon', 'Spacing22pxIcon', 'Spacing24pxIcon']
  static typeFaceTargets = ['typeFaceBox', 'typeFaceSelected', 'sourceSansProFontIcon', 'openDyslexicFontIcon', 'verdanaFontIcon', 'sourceSerifFontIcon', 'merriweatherFontIcon', 'georgiaFontIcon']
  static fontSizeTargets = ['fontSizeBox', 'fontSizeSelected', 'Size16pxIcon', 'Size18pxIcon', 'Size20pxIcon']
  static targets = ['dropdown', 'button', 'appearanceBox', ...this.typeFaceTargets, ...this.fontSizeTargets, ...this.lineSpacingTargets]

  /* ----------------------------------FONTS--------------------------------- */
  toggleFont(event) {
    const iconTargets = [this.sourceSansProFontIconTarget, this.openDyslexicFontIconTarget, this.verdanaFontIconTarget, this.sourceSerifFontIconTarget, this.merriweatherFontIconTarget, this.georgiaFontIconTarget]
    const fontNames = ['Source Sans Pro', 'Open Dyslexic', 'Verdana', 'Source Serif', 'Merriweather', 'Georgia']

    let icon_index = event.currentTarget.dataset.indexValue
    icon_index = parseInt(icon_index)
    let selectedFont = fontNames[icon_index]
    const content = document.querySelectorAll('.content-item')

    // for icon targets except at the icon_index, if they don't have hidden class, add it
    // at the icon_index, if they have hidden class, remove it
    for (let i = 0; i < iconTargets.length; i++) {
      if (i !== icon_index && !iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.add('hidden')
      }

      if (i === icon_index && iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.remove('hidden')
      }
    }

    // special case for open dyslexic font
    if (selectedFont === 'Open Dyslexic') {
      for (let i = 0; i < content.length; i++) {
        // add dyslexia class to content if it doesn't have it
        if (!content[i].classList.contains('dyslexia')) {
          content[i].classList.add('dyslexia')
        }
      }

      // Mixpanel event
      const distinctUserId = document.querySelector('.main-document').dataset.userId
      mixpanel.track('Document Change Dyslexia Font', { 'distinct_id': distinctUserId })
    } else {
      // load font from google fonts
      WebFont.load({
        google: {
          families: [selectedFont]
        }
      })

      // toggle selected font class on content
      for (let i = 0; i < content.length; i++) {
        // remove dyslexia class from content if it has it
        if (content[i].classList.contains('dyslexia')) {
          content[i].classList.remove('dyslexia')
        }

        content[i].style.fontFamily = selectedFont
      }
    }

    // change content of typeface selected to selectedFont
    this.typeFaceSelectedTarget.textContent = selectedFont
    this.saveSettings({ 'web_typeface': selectedFont })
  }
  /* ------------------------------------------------------------------------ */

  /* ------------------------------OPTION BOXES------------------------------ */
  toggleOptionBoxes(event) {
    let index = event.currentTarget.dataset.indexValue
    index = parseInt(index)
    const boxes = [this.appearanceBoxTarget, this.typeFaceBoxTarget, this.fontSizeBoxTarget, this.lineSpacingBoxTarget]

    for (let i = 0; i < boxes.length; i++) {
      if (i !== index && !boxes[i].classList.contains('hidden')) {
        boxes[i].classList.add('hidden')
      }

      if (i === index && boxes[i].classList.contains('hidden')) {
        boxes[i].classList.remove('hidden')
      }
    }
  }
  /* ------------------------------------------------------------------------ */

  /* --------------------------------FONT SIZE------------------------------- */
  toggleFontSize(event) {
    const fontSizes = ['16px', '18px', '20px']
    const iconTargets = [this.Size16pxIconTarget, this.Size18pxIconTarget, this.Size20pxIconTarget]

    let icon_index = event.currentTarget.dataset.indexValue
    icon_index = parseInt(icon_index)
    let selectedFontSize = fontSizes[icon_index]
    const content = document.querySelectorAll('.content-item')

    // for icon targets except at the icon_index, if they don't have hidden class, add it
    // at the icon_index, if they have hidden class, remove it
    for (let i = 0; i < iconTargets.length; i++) {
      if (i !== icon_index && !iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.add('hidden')
      }

      if (i === icon_index && iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.remove('hidden')
      }
    }

    // toggle selected font class on content
    for (let i = 0; i < content.length; i++) {
      content[i].style.fontSize = selectedFontSize
    }

    // change content of typeface selected to selectedFont
    this.fontSizeSelectedTarget.textContent = selectedFontSize
    this.saveSettings({ 'web_font_size': selectedFontSize })

    // Mixpanel event
    const distinctUserId = document.querySelector('.main-document').dataset.userId
    mixpanel.track('Document Change Text Size', { 'distinct_id': distinctUserId })
  }
  /* ------------------------------------------------------------------------ */

  /* ------------------------------LINE SPACING------------------------------ */
  toggleLineSpacing(event) {
    const lineHeights = ['1.2', '1.6', '2.0', '2.4', '2.8']
    const iconTargets = [this.Spacing16pxIconTarget, this.Spacing18pxIconTarget, this.Spacing20pxIconTarget, this.Spacing22pxIconTarget, this.Spacing24pxIconTarget]

    let icon_index = event.currentTarget.dataset.indexValue
    icon_index = parseInt(icon_index)
    let selectedLineHeight = lineHeights[icon_index]
    const content = document.querySelectorAll('.content-item')

    // for icon targets except at the icon_index, if they don't have hidden
    // class, add it at the icon_index, if they have hidden class, remove it
    for (let i = 0; i < iconTargets.length; i++) {
      if (i !== icon_index && !iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.add('hidden')
      }

      if (i === icon_index && iconTargets[i].classList.contains('hidden')) {
        iconTargets[i].classList.remove('hidden')
      }
    }

    // toggle selected line height class on content
    for (let i = 0; i < content.length; i++) {
      content[i].style.lineHeight = selectedLineHeight
    }

    this.lineSpacingSelectedTarget.textContent = selectedLineHeight
    this.saveSettings({ 'web_line_height': selectedLineHeight })
    // Mixpanel event
    const distinctUserId = document.querySelector('.main-document').dataset.userId
    mixpanel.track('Document Change Line Spacing', { 'distinct_id': distinctUserId })
  }
  /* ------------------------------------------------------------------------ */

  /* ---------------------------------THEMES--------------------------------- */
  toggleTheme(event) {
    let index = event.currentTarget.dataset.indexValue
    index = parseInt(index)
    const themes = ['dark', 'bright', 'peach', 'banana']
    const background_colors = ['#23273F', '#FFF', '#EDD1B0', '#F9F9B2']

    if (index === 0 && document.body.classList.contains('other')) {
      document.body.classList.remove('other')
      document.querySelector('#main-document').classList.remove('[color-scheme:light]')
    }

    for (let i = 0; i < themes.length; i++) {
      if (i !== index && document.body.classList.contains(themes[i])) {
        document.body.classList.remove(themes[i])
      }

      // special case for index 0, which is the default theme
      if (i === index && !document.body.classList.contains(themes[i])) {
        document.body.classList.add(themes[i])
        this.saveSettings({ 'web_theme': themes[i] })
      }

      if (i === index && i !== 0 && !document.body.classList.contains('other')) {
        document.body.classList.add('other')
      }
    }

    $('.document-appearance-dropdown').css('background', background_colors[index]);

    // for non-dark theme, set light color scheme to the main element
    if (index !== 0) {
      document.querySelector('#main-document').classList.add('[color-scheme:light]')
    }

  }

  // save the settings

  saveSettings(setting) {
    fetch('/user_settings', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          user_setting: { ...setting },
          authenticity_token: document.querySelector('meta[name="csrf-token"]').content
        }
      )
    }).then(response => response.json())
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // save the settings

  saveSettings(setting) {
    fetch('/user_settings', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          user_setting: { ...setting },
          authenticity_token: document.querySelector('meta[name="csrf-token"]').content
        }
      )
    }).then(response => response.json())
      .catch((error) => {
        console.error('Error:', error)
      })
  }
  /* ------------------------------------------------------------------------ */
}
