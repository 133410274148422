
import { Controller } from '@hotwired/stimulus'
import ReadAlongVisibilityHandler from '../utils/ReadAlongVisibilityHandler'

export default class extends Controller {
  connect() {
    this.paragraphsMap = new Map()
    this.resultObj = {}
    this.highlightParagraphTrack = {}
    this.attachClickEvents()
    this.hiddenAudioPlayer = document.getElementById('hidden-audio-player')
    this.hiddenAudioPlayer.addEventListener('timeupdate', this.startSectionHighlights.bind(this))
    this.hiddenAudioPlayer.addEventListener('ended', this.resetSpeechMarksObject.bind(this))
    this.hiddenAudioPlayer.addEventListener('play', this.fetchSpeechMarksData.bind(this))
    this.attachReadAlongSwitchEvent()
  }

  attachReadAlongSwitchEvent() {
    document.addEventListener('change', (event) => {
      if (event.target && event.target.id === 'readAlongSwitch') {
        this.clearParagraphHighlights()
        this.handleToggleSwitchUpdate(event)
      }
    })
  }

  // Click on single word should select whole paragraph
  highlightParagraph(){
     let paragraph = document.querySelector('.read-along-section-bg')
     let range, selection;

      if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(paragraph);
        range.select();
      } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(paragraph);
        selection.removeAllRanges();
        selection.addRange(range);
      }
  }

  // Commented as need to confirm wheather we need this - for now Save notes on one click on read along paragraph
  saveNotes(){
    // Logic to close overlays and show flashes (error or success)
    const highlightSuccess = () => {
      let successFlash = document.querySelector('.flash-success')
      successFlash.querySelector('.flash-text').innerText = 'Highlight created successfully!'
      successFlash.classList.remove('invisible')
      successFlash.classList.remove('hide-animation')
      setTimeout(() => {
        successFlash.classList.add('hide-animation')
      }, 50)
    }
    const highlightFailure = () => {
      let errorFlash = document.querySelector('.flash-error')
      errorFlash.querySelector('.flash-text').innerText = 'There was an error :('
      errorFlash.classList.remove('invisible')
      errorFlash.classList.remove('hide-animation')
      setTimeout(() => {
        errorFlash.classList.add('hide-animation')
      }, 50)
    }

    // Send ajax request to save notes
    async function postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      })
      return response
    }

    let documentId = this.currentDocumentId()
    let paragraphId = document.querySelector('.read-along-section-bg').dataset.paragraphId
    postData(`${process.env.BASE_URL}/api/v2/highlights`, { highlight: { document_id: documentId, paragraph_id: paragraphId } })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          highlightSuccess()
        } else {
          highlightFailure()
        }
      })
      .catch((e) => {
        highlightFailure()
      })
  }

  attachClickEvents() {
    // Fix the click event to not be called repeatedly on the target attribute.
    this.tabs = document.querySelectorAll('.show-document-section-header')
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].addEventListener('click', () => this.fetchSpeechMarksData())
      this.tabs[i].addEventListener('click', () => this.clearParagraphHighlights())
    }
  }

  clearParagraphHighlights() {
    document.querySelectorAll('.read-along-section-bg').forEach(element => element.classList.remove('read-along-section-bg'))
    document.querySelectorAll('.read-along-highlight').forEach(element => element.classList.remove('read-along-highlight'))
  }

  async handleToggleSwitchUpdate(event) {
    const { checked } = event.target
    const operation = checked ? 'enable' : 'disable'
    let loadingAnimation = document.getElementById('loading-animation')
    let hiddenAudioPlayer = document.getElementById('hidden-audio-player')
    let playButton = document.getElementById('play-button')
    let pauseButton = document.getElementById('pause-button')
    let playButtonWrapper = document.getElementById('play-button-wrapper')
    if(operation == 'enable'){
      loadingAnimation.classList.remove('hidden')
      hiddenAudioPlayer.pause()
      playButton.classList.add('hidden')
      pauseButton.classList.add('hidden')
      playButtonWrapper.classList.add('hidden')
    }
    try {
      await ReadAlongVisibilityHandler.refreshReadAlongForSection(this.currentDocumentId(), this.currentSectionId(), operation)
      loadingAnimation.classList.add('hidden')
      pauseButton.classList.remove('hidden')
      playButtonWrapper.classList.remove('hidden')
      hiddenAudioPlayer.play()
      this.fetchSpeechMarksData()
    } catch (error) {
      console.error('Error refreshing Read Along:', error)
    }
  }

  currentSectionFrameId() {
    const activeSection = document.querySelector('.content-item:not(.hidden)')
    return activeSection.querySelector('[id]').id
  }

  currentSectionId() {
    return document.querySelector('.content-item:not(.hidden)').getAttribute('data-section-id')
  }

  currentDocumentId() {
    return document.querySelector('.content-item:not(.hidden)').getAttribute('data-document-id')
  }

  resetSpeechMarksObject(){
    console.log("********* Calling resetSpeechMarksObject ******* ")
    this.speechMarksDataObj = {};
    this.paragraphsMap.clear();
    this.resultObj = {};
    console.log("********* Called resetSpeechMarksObject ******* ", this.speechMarksDataObj)
  }

  async fetchSpeechMarksData() {
    console.log('***** Getting speech marks data for section *****')

    // Clear speechMarksDataObj
    this.resetSpeechMarksObject()

    const activeSectionId = this.currentSectionId()
    const sectionDocumentId = this.currentDocumentId()

    const url = `/documents/${sectionDocumentId}/sections/${activeSectionId}/speech_marks_data`

    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error('Failed to fetch speech marks data')
      }
      const data = await response.json()
      this.speechMarksDataObj = {
        speech_marks: data.speech_marks,
        content: data.content,
        read_along_enabled: data.read_along_enabled,
        total_paragraphs: data.paragraphs_meta_data.total_paragraphs,
        paragraph_ids: data.paragraphs_meta_data.paragraph_ids
      }
    } catch (error) {
      console.error('Error occurred:', error.message)
    }
  }

  startSectionHighlights(event) {
    if (!this.displaySpeechMarks()) return

    const audio = event.target
    const currentTime = Math.floor(audio.currentTime * 1000)

    const originalTextContent = this.speechMarksDataObj.content

    // Sort the speech marks array based on the 'time' property
    // most likely already arranged according to time property
    this.sortSpeechMarksByTime()

    let closestWordIndex = this.findClosestWord(currentTime)

    if (closestWordIndex !== -1) {
      const closestWord = this.speechMarksDataObj.speech_marks[closestWordIndex]
      const startIndex = closestWord.start
      const endIndex = closestWord.end

      const highlightedText =
      originalTextContent.substring(0, startIndex) +
      `<span class="read-along-highlight">${originalTextContent.substring(startIndex, endIndex)}</span>` +
      originalTextContent.substring(endIndex)

      const highlightRegex = /<span class=['"]read-along-highlight['"]>.*?<\/span>/

      const paragraphs = highlightedText.split('\n\n')

      this.speechMarksDataObj.paragraph_ids.forEach((id, index) => {
        this.paragraphsMap.set(id, paragraphs[index])
        this.highlightParagraphTrack[id] = 'inactive'
      })

      this.resultObj = {}

      for (let [key, value] of this.paragraphsMap) {
        if (highlightRegex.test(value)) {
          this.highlightParagraphTrack[key] = 'active'
          this.resultObj[key] = value
        }
      }

      // return if no highlighted paragraph is captured
      if (Object.keys(this.resultObj).length === 0) {
        return
      }

      const currentHighlightedParagraph = this.getCurrentHighlightedParagraph()

      this.removeInactiveParagraphHighlights()

      this.makeTurboRequest(currentHighlightedParagraph)
    }
  }

  getCurrentHighlightedParagraph() {
    const keys = Object.keys(this.resultObj)
    const lastKey = keys[keys.length - 1]
    return { [lastKey]: this.resultObj[lastKey] }
  }

  sortSpeechMarksByTime() {
    this.speechMarksDataObj.speech_marks.sort((a, b) => a.time - b.time)
  }

  removeInactiveParagraphHighlights() {
    for (var paragraphId in this.highlightParagraphTrack) {
      if (Object.prototype.hasOwnProperty.call(this.highlightParagraphTrack, paragraphId) && this.highlightParagraphTrack[paragraphId] === 'inactive') {
        const paragraph = document.querySelector('p[data-paragraph-id="' + paragraphId + '"]')
        if (paragraph) {
          paragraph.classList.remove('read-along-section-bg')
          const highlights = paragraph.querySelectorAll('.read-along-highlight')
          highlights.forEach(function(element) {
            element.classList.remove('read-along-highlight')
          })
        }
      }
    }
  }

  findClosestWord(targetTime) {
    let left = 0
    let right = this.speechMarksDataObj.speech_marks.length - 1
    let closestIndex = -1

    while (left <= right) {
      const mid = Math.floor((left + right) / 2)
      const midTime = this.speechMarksDataObj.speech_marks[mid].time

      if (midTime === targetTime) {
        return mid
      } else if (midTime < targetTime) {
        left = mid + 1
      } else {
        right = mid - 1
      }

      if (closestIndex === -1 || Math.abs(targetTime - midTime) < Math.abs(targetTime - this.speechMarksDataObj.speech_marks[closestIndex].time)) {
        closestIndex = mid
      }
    }

    return closestIndex
  }

  makeTurboRequest(data) {
    const sectionId = this.currentSectionId()
    const documentId = this.currentDocumentId()

    // build an object like {paragraph_id: '1234, paragraph_content: 'abc'} to post to the
    // read along action in the sections controller
    const highlightedParagraphObj = Object.entries(data).map(([paragraph_id, paragraph_content]) => ({ paragraph_id, paragraph_content }))[0]

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'text/vnd.turbo-stream.html',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    return fetch(`/documents/${documentId}/sections/${sectionId}/read_along_highlight`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(highlightedParagraphObj)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to make turbo request')
        }
        return response.text()
      })
      .then(html => {
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(html)
      })
      .catch(error => {
        console.error('Error:', error)
        throw error // Rethrow the error for handling by caller
      })
  }


  displaySpeechMarks() {
    if (!this.speechMarksDataObj || Object.keys(this.speechMarksDataObj).length === 0) return false

    if (this.speechMarksDataObj.speech_marks.length === 0) {
      console.log('*** No speech marks found for document ***')
      return false
    }

    if (this.speechMarksDataObj.read_along_enabled === false) {
      console.log('*** Read along disabled for the document ***')
      // reset highlighted paragraph if flag is disabled during the audio is playing
      this.clearParagraphHighlights()
      return false
    }

    return true
  }
}
