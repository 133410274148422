import { Controller } from "@hotwired/stimulus"
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

// Connects to data-controller="document-tutorial"
export default class extends Controller {
  connect() {
    this.tutorial = new Object()

    // Use setTimeout to ensure tutorial runs after other JS code is executed
    setTimeout(() => this.driveDesktopOrMobileTutorial(), 0)

    window.addEventListener('resize', () => {
      this.tutorial.destroy()
      this.driveDesktopOrMobileTutorial()
    })

    this.markUserAsTutored()
  }

  markUserAsTutored() {
    fetch(`${process.env.BASE_URL}/api/v1/users/${document.body.dataset.userId }`, {
      method: 'PATCH',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ user: { tutored: true } })
    })
  }

  driveDesktopOrMobileTutorial() {
    if (document.documentElement.clientWidth >= 1024) {
      this.driveDesktopTutorial()
    } else {
      this.driveMobileTutorial()
    }
  }

  driveDesktopTutorial() {
    const appearanceDropdownTrigger = this.getAppearanceDropdownTrigger()
    const appearanceDropdownContent = this.getAppearanceDropdownContent()
    const speedDropdownTrigger = this.getSpeedDropdownTrigger()
    const playButton = this.getPlayButton()

    const tutorial = driver({
      showProgress: true,
      allowClose: false,
      // stagePadding: 0,
      showButtons: ['next'],
      steps: [
        { // Step 0
          element: '.text-options-dropdown-container',
          onHighlighted: () => {
            appearanceDropdownTrigger.addEventListener('click', function handleClick() {
              tutorial.moveTo(1)

              // Remove the event listener after it's been triggered once
              appearanceDropdownTrigger.removeEventListener('click', handleClick)
            })
          },
          popover: {
            title: 'Reading experience',
            description: 'Personalize your reading experience from here.',
            side: 'right',
            align: 'start',
            onNextClick: () => this.handleStepZeroNext()
          },
        },
        { // Step 1
          element: '.document-appearance-dropdown',
          onHighlighted: () => appearanceDropdownContent.addEventListener('click', highlightStepOne),
          onDeselected: () => {
            this.handleStepOneDeselected()
            appearanceDropdownContent.removeEventListener('click', highlightStepOne)
          },
          popover: {
            title: 'Appearance options',
            description: 'Choose your color theme, text appearance, and more..',
            side: 'right',
            align: 'start'
          }
        },
        { // Step 2
          element: '.audio-speed-dropdown-container',
          onHighlighted: () => {
            speedDropdownTrigger.addEventListener('click', function handleClick() {
              tutorial.moveTo(3)

              // Remove the event listener after it's been triggered once
              speedDropdownTrigger.removeEventListener('click', handleClick)
            })
          },
          popover: {
            title: 'Listening experience',
            description: 'Personalize your listening experience from here.',
            side: 'top',
            align: 'end',
            onNextClick: () => this.handleStepTwoNext()
          }
        },
        { // Step 3
          element: '#audio-speed-options',
          onDeselected: () => this.handleStepThreeDeselected(),
          popover: {
            title: 'Playback speed',
            description: 'Choose how fast or slow the audio will play.',
            side: 'right',
            align: 'start'
          }
        },
        { // Step 4
          element: '#play-button-wrapper',
          onHighlighted: () => {
            playButton.addEventListener('click', function handleClick() {
              tutorial.moveNext()

              // Remove the event listener after it's been triggered once
              playButton.removeEventListener('click', handleClick)
            })
          },
          popover: {
            title: 'All set up!',
            description: 'Start listening to your paper now.',
            side: 'top',
            align: 'center'
          }
        }
      ]
    })

    tutorial.drive()

    this.tutorial = tutorial

    // This helps adjust driver-js' backdrop size because appearance box size
    // changes when you click around to enter its different controls
    function highlightStepOne() {
      tutorial.moveTo(1)
    }
  }

  driveMobileTutorial() {
    const playButton = this.getPlayButton()

    const tutorial = driver({
      showProgress: true,
      allowClose: false,
      disableActiveInteraction: true,
      // stagePadding: 0,
      showButtons: ['next'],
      steps: [
        { // Step 0
          element: '#appearance-trigger-mobile',
          popover: {
            title: 'Reading experience',
            description: 'Personalize your reading experience from here.',
            side: 'top',
            align: 'start'
          },
        },
        { // Step 1
          element: '#audio-speed-button',
          popover: {
            title: 'Listening experience',
            description: 'Personalize your listening experience from here.',
            side: 'top',
            align: 'end'
          },
        },
        { // Step 2
          element: '#play-button-wrapper',
          onHighlightStarted: () => {
            tutorial.setConfig({
              disableActiveInteraction: false,
              // This allows `align: center` to work on narrow screens
              popoverClass: 'narrow-tutorial-popover'
            })
          },
          onHighlighted: () => {
            playButton.addEventListener('click', function handleClick() {
              tutorial.moveNext()

              // Remove the event listener after it's been triggered once
              playButton.removeEventListener('click', handleClick)
            })
          },
          popover: {
            title: 'All set up!',
            description: 'Start listening now.',
            side: 'top',
            align: 'center'
          },
        }
      ]
    })

    tutorial.drive()

    this.tutorial = tutorial
  }

  // Opens appearance box if user hasn't already opened it manually
  handleStepZeroNext() {
    if (this.getAppearanceDropdownContent().ariaExpanded === 'true') return

    this.getAppearanceDropdownTrigger().click()
  }

  // Closes appearance box if user hasn't already closed it manually
  handleStepOneDeselected() {
    if (this.getAppearanceDropdownContent().ariaExpanded === 'false') return

    this.getAppearanceDropdownTrigger().click()
  }

  // Closes playback speed box if user hasn't already closed it manually
  handleStepTwoNext() {
    if (this.getSpeedDropdownContent().ariaExpanded === 'true') return

    this.getSpeedDropdownTrigger().click()
  }

  // Closes playback speed box if user hasn't already closed it manually
  handleStepThreeDeselected() {
    if (this.getSpeedDropdownContent().ariaExpanded === 'false') return

    this.getSpeedDropdownTrigger().click()
  }

  getAppearanceDropdownTrigger() {
    return document.querySelector('.document-appearance-title-box')
  }

  getAppearanceDropdownContent() {
    return document.getElementById('appearance-dropdown-content')
  }

  getSpeedDropdownTrigger() {
    return document.getElementById('audio-speed-button')
  }

  getSpeedDropdownContent() {
    return document.querySelector('.audio-speed-dropdown')
  }

  getPlayButton() {
    return document.getElementById('play-button')
  }
}
