import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="google-analytics"
export default class extends Controller {
  connect() {
    const purchaseData = JSON.parse(this.data.get("ga4"))
    if (purchaseData.synced == false) {
      console.log('Doing that thing with ga4 stuff')
      window.dataLayer = window.dataLayer || []
      dataLayer.push({ ecommerce: null }) 
        dataLayer.push({
          'event': "purchase",
          'ecommerce': purchaseData.ga4
        })
      }else{
        console.log('Still false')
      }
  }
}
