import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="textarea"
export default class extends Controller {
  connect() {
    this.resize();
  }

  resize() {
    const element = this.element;
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  }
}
