import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

// Connects to data-controller="tel-flag"
export default class extends Controller {
  static targets = [ 'mobile' ]

  connect() {
    async function getIp(callback) {
      // First fetch the IP address.
      let userIp = document.getElementById('user_ip').value

      // Then fetch the country code
      const res2 = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.IP_GEOLOCATION}&ip=${userIp}`)
      if (res2.ok) {
        const data2 = await res2.json()
        return callback(data2.country_code2)
      }
      // If we can't get the country code, we'll just return the US
      return callback('us')
    }

    intlTelInput(this.mobileTarget, {
      // any initialisation options go here
      // The utils script should be the one provided by the intl-tel-input library
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
      // you can use the hiddenInput option to automatically create a hidden input that gets populated with the full international number on submit
      hiddenInput: 'phone_number', // with this option, the full international number will be submitted
      preferredCountries: ['us', 'gb', 'es'],
      separateDialCode: true, // Display the dial code separately from the number
      autoPlaceholder: 'polite', // Don't display over any placeholders, set to aggressive to display over any placeholders
      initialCountry: 'auto',
      geoIpLookup: getIp,
    })

    this.element.querySelector('.iti__selected-flag').tabIndex = '-1'
  }

  makeFlagTabbable() {
    this.element.querySelector('.iti__selected-flag').tabIndex = '0'
  }
}
