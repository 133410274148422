import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="resubscribe"
export default class extends Controller {
  static targets = ["circle", "heading"]
  static index = 0
  connect() {
    if(this.constructor.index === 0){
      this.constructor.index = 1
      this.autoClick()
    }
  }

  autoClick() {
    this.interval = setInterval(() => {
      this.clickNext()
    }, 3000)
  }

  clickNext() {
    const activeStep = document.querySelector('.step.active');
    if (activeStep) {
      activeStep.dispatchEvent(new Event('click', { bubbles: true }));
    }

    let nextStep = activeStep.nextElementSibling;
    while (nextStep && !nextStep.classList.contains('step')) {
      nextStep = nextStep.nextElementSibling;
    }
    // If we reached the end of the list, start from the beginning
    if (!nextStep) {
      nextStep = document.querySelector('.step');
    }
    if (nextStep) {
      activeStep.classList.remove('active');
      nextStep.classList.add('active');
    }
  }

  changeStep(event) {
    document.querySelectorAll('.resubscribe-steps').forEach(element => {
      element.setAttribute('fill', '#2B3148');
    });
    this.circleTarget.setAttribute('fill', '#A0EFEA')
    const activeElements = document.querySelectorAll(`[data-id='${this.circleTarget.id}']`);
    const allElements = document.querySelectorAll('.toggle-element');
    allElements.forEach(element => {
      element.classList.add('hidden');
    });
    activeElements.forEach(element => {
      element.classList.remove('hidden');
    });
  }
}
