import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="select-or-other"
export default class extends Controller {
  static targets = [
    'realSelect',
    'realOther',
    'fakeInput',
    'fakeSelection',
    'dropdown',
    'fakeOther',
    'editButton',
  ]

  connect() {
    this.realSelectTarget.value = null
    this.fakeInputTarget.classList.remove('hidden')
    this.realOtherTarget.tabIndex = -1
    this.realOtherTarget.type = 'hidden'

    this.element.addEventListener('focusout', this.handleFocusOut.bind(this))
    this.element.addEventListener('focusin', this.handleFocusIn.bind(this))
    this.losingFocus = false

    if (this.element.dataset.autofocus) this.showDropdown()
  }

  disconnect() {
    this.element.removeEventListener('focusout', this.handleFocusOut.bind(this))
    this.element.removeEventListener('focusin', this.handleFocusIn.bind(this))
  }

  handleFocusOut() {
    // Set a flag to indicate that focus might be moving outside
    this.losingFocus = true

    // 0 ms timeout serves the purpose of placing this call in queue, ensuring
    // it will execute after handleFocusIn is complete
    setTimeout(() => {
      if (this.losingFocus) this.hideDropdown()
    }, 0)
  }

  handleFocusIn() {
    // If dropdown is not already visible, show it
    this.showDropdown()

    // If focus moves inside the controller, clear the flag
    this.losingFocus = false
  }

  showDropdown() {
    this.dropdownTarget.classList.remove('hidden')
  }

  hideDropdown() {
    this.dropdownTarget.classList.add('hidden')
  }

  selectOption(event) {
    event.preventDefault()
    this.realSelectTarget.value = event.target.dataset.value
    this.realOtherTarget.value = null

    this.fakeSelectionTarget.innerText = event.target.innerText

    this.afterSelect()
  }

  selectOther() {
    this.realSelectTarget.value = this.realSelectTarget.dataset.otherTrigger
    this.realOtherTarget.value = this.fakeOtherTarget.value

    this.fakeSelectionTarget.innerText = this.fakeOtherTarget.value

    this.afterSelect()
  }

  selectInputSelect(event) {
    event.preventDefault()
    console.log(event.target.innerText)
    this.realSelectTarget.value = event.target.dataset.value

    this.fakeSelectionTarget.innerText = event.target.innerText
    this.afterSelect()
  }

  afterSelect() {
    this.fakeSelectionTarget.classList.remove('hidden')
    this.fakeInputTarget.classList.add('hidden')
    this.calculateFakeInputWidth()
    this.editButtonTarget.classList.remove('hidden')
    this.hideDropdown()
  }

  simulateClick(event) {
    event.target.click()
  }

  startEdition() {
    this.fakeSelectionTarget.innerText = null
    this.realSelectTarget.value = null
    this.realOtherTarget.value = null
    this.fakeInputTarget.classList.remove('hidden')
  }

  calculateFakeInputWidth() {
    this.fakeInputTarget.style.width = `${this.fakeSelectionTarget.offsetWidth}px`
  }

  preventFormSubmission(event) {
    event.preventDefault()
  }
}
