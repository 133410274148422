// app/javascript/controllers/payment_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.startCheckingPaymentStatus()
  }

  startCheckingPaymentStatus() {
    this.interval = setInterval(() => {
      this.checkPaymentStatus()
    }, 2000)
  }

  checkPaymentStatus() {
    fetch("/check-payment-status")
      .then(response => response.json())
      .then(data => {
        if (data.is_payment_completed) {
          this.showSubscriptionModal()
          clearInterval(this.interval)  // Stop checking when payment is completed
        } else {
          this.showLoading()
        }
      })
  }

  showLoading() {
    document.querySelector('.loading-section').classList.remove("hidden-important")
    document.querySelector('.subscription-modal-overlay').classList.add("hidden-important")
  }

  showSubscriptionModal() {
    document.querySelector('.loading-section').classList.add("hidden-important")
    document.querySelector('.subscription-modal-overlay').classList.remove("hidden-important")
  }

  disconnect() {
    clearInterval(this.interval)  // Clean up when the controller is disconnected
  }
}
