const mailAnimation = () => {
  const lottiePlayer = document.querySelector('lottie-player');
  if (lottiePlayer) {
    setTimeout(() => {
      lottiePlayer.play();
      setInterval(() => {
        lottiePlayer.stop();
        lottiePlayer.play();
      }, 3000);
    }, 1500);
  }
}
export { mailAnimation }
