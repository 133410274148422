import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const ua = navigator.userAgent

    // Make sure it's Safari, Chrome check is needed to prevent false positives
    if (ua.indexOf('Safari') != -1 && ua.indexOf('Chrome') == -1) {
      this.element.addEventListener('focus', e => {
        setTimeout(() => { e.target.select() }, 100)
      })
    }
  }
}
