import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.hiddenAudioPlayer = document.getElementById('hidden-audio-player');
    this.audio = new Audio();
    this.audioUrl = document.querySelector('#title-audio-btn').getAttribute('data-audio-url');
    this.audio.addEventListener('timeupdate', this.handleMainPlayerState.bind(this));
  }

  toggleTitleAudioPlayback() {
    if (this.audio && !this.audio.paused) {
      this.audio.pause();
    } else {
      this.hiddenAudioPlayer.pause();
      this.audio.src = this.audioUrl;
      this.audio.play();
    }
  }

  handleMainPlayerState(){
    if (this.hiddenAudioPlayer && !this.hiddenAudioPlayer.paused) {
      this.audio.pause();
    }
  }
}
