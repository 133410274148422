import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="apple"
export default class extends Controller {
  static targets = ['appleLogin']
  appleLogin() {
    this.appleLoginTarget.querySelector('div').click()

  }
}
