// TODOs:
// - basePath is not set correctly on /:broadcaster/broadcasts page, we are
//   getting /:broadcaster/broadcasts/broadcasts page instead
// - search function doesn't cancel itself if user types another char within
//   the 500 ms gap. This causes superfluous requests that are cancelled from
//   browser, causing mobile data waste for the users, and extra Sentry messages
//   to read for us
// - Language cannot be toggled via keyboard. Listen to key press (Enter) when
//   dropdown is open

import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['languageToggler', 'search']
  static basePath = `${window.location.pathname}/broadcasts`

  connect() {
    this.searchTarget.addEventListener('input', () => {
      setTimeout(() => this.search(), 500)
    })
  }

  search() {
    const language = this.currentLanguage()
    const keyword = this.searchTarget.value

    Turbo.visit(
      `${this.constructor.basePath}?language=${language}&keyword=${keyword}`,
      { frame: 'broadcasts_list_items' }
    )
  }

  toggleLanguage() {
    const language = this.currentLanguage()

    this.searchTarget.value = null // Clear the keyword search input

    Turbo.visit(
      `${this.constructor.basePath}?language=${language}`,
      { frame: 'broadcasts_list_items' }
    )
  }

  resetFilters() {
    // Passing empty language to reset language to default from the backend
    Turbo.visit(
      `${this.constructor.basePath}?language=`,
      { frame: 'broadcasts_list' }
    )
  }

  currentLanguage() {
    // Set default return value to 'EN'
    let selectedLanguage = 'EN'

    for (let languageToggler of this.languageTogglerTargets) {
      if (!languageToggler.checked) continue

      selectedLanguage = languageToggler.value
      break
    }

    return selectedLanguage
  }

  basePath() {
    return `${window.location.pathname}/broadcasts`
  }
}
