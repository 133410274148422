import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="add-paper-to-folder"
export default class extends Controller {
  closeFoldersModal(){
    this.element.classList.add('hidden')

    //return focus to the button that opened the modal when you close the modal
    const addPaperToFolderBtn = document.querySelector('.add-paper-to-folder')
    addPaperToFolderBtn.focus()
  }

  addPaperToExistingFolder(e){
    let form = document.querySelector('.new-document-folder-form')
    let folderInput = document.getElementById('document_folder_folder')
    const folderId = e.currentTarget.dataset.folderId
    folderInput.value = folderId
    form.submit()
  }

}
