import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pdf-viewer"
export default class extends Controller {
  static targets = ['pdf', 'tab']

  connect() {
    if (!this.hasPdfTarget) {
      return
    }
    const tab = this.tabTarget
    const pdf = this.pdfTarget

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      tab.style.display = 'none'
      pdf.style.display = 'none'
    }
  }
}
