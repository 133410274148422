import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-section-tabs"
let showingLeftArrow = false
export default class extends Controller {

  static targets = ["leftBtn"]

  rightScroll(){
    this.element.querySelector('.grouped-section-headers').scrollBy({
      top: 0,
      left: 800,
      behavior: 'smooth'
    });
  }

  leftScroll(){
    this.element.querySelector('.grouped-section-headers').scrollBy({
      top: 0,
      left: -800,
      behavior: 'smooth'
    });
  }

  checkScroll(e){
    // Prevents left button from showing in small screens
    if (screen.width < 980) { return; }

    if (!showingLeftArrow && e.target.scrollLeft > 0) {
      // Show left arrow
      this.leftBtnTarget.style.display = "block"
      showingLeftArrow = true;
    } else if (showingLeftArrow && e.target.scrollLeft === 0) {
      // Hide left arrow
      this.leftBtnTarget.style.display = "none";
      showingLeftArrow = false;
    }

  }

}
