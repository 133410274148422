import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
    'submit',
    'hint',
    'defaultHint',
    'optimistHint',
    'pessimistHint',
    'neutralHint'
  ]

  connect() {}

  checkUrlQuality() {
    // Return early if input doesn't have a valid URL
    if (this.inputTarget.checkValidity() === false) {
      this.showHint(this.defaultHintTarget)
      return false
    }

    this.submitTarget.disabled = true

    // eslint-disable-next-line no-undef
    fetch(`${process.env.BASE_URL}/api/v1/imports/predict_odds`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ import: { url: this.inputTarget.value } })
    }).then(response => response.json())
      .then(data => {
        this.updateHint(data.import.success_odds)
      })
      .catch(e => {
        console.log('Encountered an error:', e)
        this.submitTarget.disabled = false
      })
  }

  updateHint(successOdds) {
    if (typeof(successOdds) === 'number') {
      if (successOdds > 50) this.showHint(this.optimistHintTarget)
      if (successOdds < 50) this.showHint(this.pessimistHintTarget)
      if (successOdds === 50) this.showHint(this.neutralHintTarget)
    } else {
      this.showHint(this.defaultHintTarget)
    }

    this.submitTarget.disabled = false
  }

  showHint(targetToShow) {
    this.hintTargets.forEach(target => {
      if (target === targetToShow) {
        target.classList.remove('hidden')
        target.classList.add('flex')
      } else {
        target.classList.remove('flex')
        target.classList.add('hidden')
      }
    })
  }
}
