import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'


// Connects to data-controller="edit-media-order"
export default class extends Controller {
  static targets = ['list']
  static values = { url: String, documentId: Number, tabRedirect: String }

  connect() {
    Sortable.create(this.listTarget)
  }

  updateOrder() {
    const ids = Array.from(this.listTarget.children).map(li => li.dataset.id)
    const data = { model_ids: ids }
    const url = this.urlValue

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    fetch(`${process.env.BASE_URL + url}`, {
      method: 'PATCH',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken // include the token in the headers
      },
      //make sure to serialize your JSON body
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.ok) {
          window.location.replace(`${process.env.BASE_URL}/documents/${this.documentIdValue}/edit?tab=${this.tabRedirectValue}`)
        }
      })
  }
}
