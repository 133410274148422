import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="paper-folder-modal"
export default class extends Controller {
  static values = {
    document: Number,
    folder: Number
  }
  static targets = ['clicktoclose', 'closebtn']

  //get the document id and pass it to the new folder so the document can be added to it
  openAddPaperToFolderModal(){
    // Set new folder link href in modal
    let folderLink = document.getElementById('add-paper-to-create-folder')
    let folderLinkMobile = document.getElementById('add-paper-to-create-folder-mobile')
    const newFolderLink = `${folderLink.href}?document_id=${this.documentValue}`
    folderLink.href = newFolderLink
    folderLinkMobile.href = newFolderLink

    let documentInput = document.getElementById('document_folder_document')
    documentInput.value = this.documentValue

    // Open add paper to folder modal
    const foldersModal = document.getElementById('add-paper-modal')
    foldersModal.classList.remove('hidden')
    this.addFocusToFirstModalElement(foldersModal)
  }

  // adding focus to the first element when modal opens
  addFocusToFirstModalElement(modal){
    const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    focusableElements[0].focus()
  }
}
