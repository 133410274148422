import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="reveal-password"
export default class extends Controller {
  static targets = ['showIcon', 'hideIcon']

  show(e) {
    // prevent form from submitting when using enter for keyboard accessibility
    if (e.key === 'Enter') {
      e.preventDefault()
    }

    // Set password to text type to reveal
    const passwordInput = this.element.querySelector('#password')
    const resetPasswordInput = this.element.querySelector('#newPassword')

    if (passwordInput) {
      passwordInput.setAttribute('type', 'text')
    } else {
      resetPasswordInput.setAttribute('type', 'text')
    }

    // Remove show icon
    this.showIconTarget.classList.add('hidden')
    // Add hide icon
    this.hideIconTarget.classList.remove('hidden')
  }

  hide(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }

    // Set password to text type to reveal
    const passwordInput = this.element.querySelector('#password')
    const resetPasswordInput = this.element.querySelector('#newPassword')

    if (passwordInput) {
      passwordInput.setAttribute('type', 'password')
    } else {
      resetPasswordInput.setAttribute('type', 'password')
    }

    // Add show icon
    this.showIconTarget.classList.remove('hidden')
    // Remove hide icon
    this.hideIconTarget.classList.add('hidden')
  }
}
