import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="onboard-visitor"
export default class extends Controller {
  static targets = ['trigger']

  connect() {
    const isOnboarded = localStorage.getItem('isOnboarded')

    if (!isOnboarded) {
      this.triggerTarget.click()
      localStorage.setItem('isOnboarded', 'true')
    }
  }
}
