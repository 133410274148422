import { Controller } from "@hotwired/stimulus"
import { mixpanel } from '../application'

// Connects to data-controller="audio-speed"
export default class extends Controller {
  static values = { playbackSpeed: Number }
  static targets = ['inputRange', 'output', 'panels', 'panelButton', 'speedForm', 'hiddenAudioPlayer']

  max = 3.00; min = 0.50; steps = 0.25

  buildControls() {
    const { panelsTarget, max, outputTarget, inputRangeTarget } = this
    if (outputTarget.textContent !== '') return

    // make selectable panel buttons
    let speed = this.min + this.steps * 2, panels = ''
    while (speed <= max) {
      panels += `<button class="panel-button text-light" data-action="audio-speed#changeSpeed" data-audio-speed-target="panelButton" data-speed-value="${speed}">${speed}x</button>`
      speed += this.steps * 2
    }
    panelsTarget.innerHTML = panels

    // make sliders for the scroll output
    speed = this.min
    let speeds = ''
    while (speed <= max) {
      speeds += `<li class="text-light">${speed.toFixed(2)}x</li>`
      speed += this.steps
    }
    outputTarget.innerHTML = speeds

    this.updateSpeed()
    inputRangeTarget.addEventListener('mouseup', () => this.handleSliderMouseUp())
    inputRangeTarget.addEventListener('touchend', () => this.handleSliderMouseUp())

  }

  handleSliderMouseUp() {
    // change final value to multiples of 0.25 (or steps)
    const { inputRangeTarget, speedFormTarget, steps } = this
    const divisor = 1 / steps
    inputRangeTarget.value = Math.round(inputRangeTarget.value * divisor) / divisor
    this.updateSpeed()
    if (this.speedFormTarget.classList.contains('public')) return
    speedFormTarget.submit()
  }

  changeSpeed(event) {
    // update the speed when a panel is clicked on
    this.inputRangeTarget.value = event.currentTarget.dataset.speedValue
    this.updateSpeed()
    if (this.speedFormTarget.classList.contains('public')) return
    this.speedFormTarget.submit()
  }

  updateSpeed() {
    const { inputRangeTarget, hiddenAudioPlayerTarget, min, max, outputTarget, panelButtonTargets } = this
    const speed = parseFloat(inputRangeTarget.value)
    hiddenAudioPlayerTarget.playbackRate = speed
    this.playbackSpeedValue = speed
    const selected = ((speed - min) / (max - min)) * 100
    inputRangeTarget.style.setProperty('--slider-value', selected + '%')


    // scroll the output to the value of the input range
    const newSpeedPosition = ((inputRangeTarget.value - min) / (max - min)) * (outputTarget.scrollHeight - outputTarget.clientHeight)
    outputTarget.scrollTop = newSpeedPosition


    // highlight the panel button that matches the input range value
    panelButtonTargets.forEach(panel => panel.classList.remove('highlighted'))
    const divisor = 1 / (this.steps * 2)
    const rounded = Math.floor(inputRangeTarget.value * divisor) / divisor

    if (rounded >= 1) {
      const panelIdx = (rounded - min) / (this.steps * 2) - 1
      const panel = this.element.querySelectorAll('.panel-button')[panelIdx]
      panel.classList.add('highlighted')
    } else {
      this.element.querySelectorAll('.panel-button')[0].classList.add('highlighted')
    }


    const distinctUserId = document.querySelector('.main-document').dataset.userId
    mixpanel.track('Document Change Speed', { 'distinct_id': distinctUserId })

  }


}
