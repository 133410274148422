import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sign-up-modal"
export default class extends Controller {
  static targets = ['modal', 'btn','overlay','highlightBtn']

  open(){
    this.overlayTarget.style.display = 'flex'
    this.addFocusToFirstModalElement(this.modalTarget)
  }

  handleClose(e){
    document.body.style.overflow = ('scroll')
    if (e.target === this.overlayTarget || e.target === this.btnTarget || e.key === 'Escape'){
      this.overlayTarget.style.display = 'none'
      // remove highlight button and text after close
      this.highlightBtnTarget.classList.add('hidden')
      let selection = window.getSelection()
      console.log(selection)
      if (selection) {
        selection.removeAllRanges()
      }
    }
  }

  // adding focus to the first element when modal opens
  addFocusToFirstModalElement(modal){
    const focusableElements = modal.querySelectorAll('button, [href]')
    focusableElements[0].focus()
  }
}
