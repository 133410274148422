import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="zotero-documents"
export default class extends Controller {
  static values = {
    groupsOpened: { type: Boolean, default: false },
  }
  static targets = [
    'menu',
    'zotero',
    'bottomMenu',
    'groups',
    'loadingAnimation',
    'userLibrary',
    'cancelBtn',
    'importBtn'
  ]

  /* -------------------------------USER FILES------------------------------- */
  goToUserCollection(e) {
    // Hide current view
    this.#hideCurrentView()

    const viewType = this.element.dataset.viewType

    let targetKey
    if (viewType === 'base') {
      targetKey = e.currentTarget.dataset.baseCollectionKey
    } else if (viewType === 'user_collection') {
      targetKey = e.currentTarget.dataset.subCollectionKey
    }

    // Update view value (used to hide current open view)
    this.element.dataset.collectionKey = targetKey
    this.element.dataset.viewType = 'user_collection'

    this.#revealTargetCollection(targetKey)
  }

  // Method for when user clicks on a collection name in the menu
  userCollectionFromMenu(e) {
    // Hide current view
    this.#hideCurrentView()

    // Show target view
    const targetKey = e.currentTarget.dataset.menuLinkKey
    this.userLibraryTarget.querySelector(`[data-collection-key="${targetKey}"]`).classList.remove('hidden')

    // Remove menu items after current view
    const elementIndex = Array.from(e.currentTarget.parentNode.children).indexOf(e.currentTarget)

    for (let i = this.menuTarget.childElementCount - 1; i > elementIndex; i--) {
      this.menuTarget.removeChild(this.menuTarget.children[i])
    }

    // Update data variable of view
    this.element.dataset.collectionKey = targetKey
  }
  /* ------------------------------------------------------------------------ */

  // Method for when user clicks on Zotero in the menu
  goToBaseView() {
    // Hide current view
    this.#hideCurrentView()

    // Display base view
    this.element.querySelector('.base-view').classList.remove('hidden')

    // Remove the underline and event listener and menu links (basically reset it)
    while (this.menuTarget.childElementCount > 2) {
      this.menuTarget.removeChild(this.menuTarget.children[2])
    }

    // Here I need to remove underline and event listener
    this.zoteroTarget.style.textDecoration = 'none'
    this.zoteroTarget.dataset.action = ''

    // Set view data variable to base
    this.element.dataset.viewType = 'base'
    this.element.dataset.collectionKey = ''
    this.element.dataset.groupId = ''
  }

  /* ---------------------------------GROUPS--------------------------------- */
  // Method for when user clicks on "Groups shared with me" in the base view
  goToGroupsFromBase() {
    // Hide current view
    this.#hideCurrentView()

    this.#setZoteroHomeButton()

    // Add the current collection name to the top menu need a new method up here
    this.menuTarget.insertAdjacentHTML('beforeend',
      `<div class="zotero-logo-text" data-action="click->zotero-documents#goToGroupsFromMenu">
         <button type="button" style="text-decoration:underline;">Groups</button>
       </div>`)

    // If not fetched yet, fetch and display
    if (this.groupsOpenedValue === false){
      // Display loading screen
      this.loadingAnimationTarget.classList.remove('hidden')

      fetch('/zotero_partials/zotero_groups_partial.html')
        .then(response => response.text())
        .then((html) => {
          // When content is loaded hide the loading screen and display groups menu
          this.loadingAnimationTarget.classList.add('hidden')
          this.groupsTarget.innerHTML = html
          this.groupsTarget.classList.remove('hidden')
        })

      this.groupsOpenedValue = 'true'
    } else {
      this.groupsTarget.querySelector('.groups-menu-view').classList.remove('hidden')
    }

    // Set view data variable to groups
    this.element.dataset.viewType = 'groups'
  }

  goToGroupsFromMenu() {
    // Hide current view
    this.#hideCurrentView()

    // Display groups view
    this.groupsTarget.querySelector('.groups-menu-view').classList.remove('hidden')

    // Remove the underline and event listener and menu links (basically reset it)
    while (this.menuTarget.childElementCount > 3) {
      this.menuTarget.removeChild(this.menuTarget.children[3])
    }

    // Set view data variable to groups
    this.element.dataset.viewType = 'groups'
    this.element.dataset.collectionKey = ''
    this.element.dataset.groupId = ''
  }

  // Method for when user clicks on a group name in the groups view
  goToGroupBase(e) {
    this.groupsTarget.querySelector('.groups-menu-view').classList.add('hidden')
    // Get group ID and name from group folder
    const groupId = e.currentTarget.dataset.groupId
    const groupName = e.currentTarget.dataset.groupName

    // Add the current collection name to the top menu need a new method up here
    this.menuTarget.insertAdjacentHTML('beforeend',
      `<div class="zotero-logo-text" data-menu-link-group-id="${groupId}" data-action="click->zotero-documents#goToGroupBaseFromMenu">
         <button type="button" style="text-decoration:underline;">${groupName}</button>
       </div>`)

    // Look for group view
    const groupView = this.groupsTarget.querySelector(`[data-group-id="${groupId}"].group-view`)

    if (groupView) {
      // Reveal group view base
      groupView.querySelector('.group-base-view').classList.remove('hidden')
    } else {
      this.loadingAnimationTarget.classList.remove('hidden')
      fetch('/zotero_partials/zotero_group_files_partial.html?group_id=' + groupId)
        .then(response => response.text())
        .then((html) => {
          // Hide loading screen
          this.loadingAnimationTarget.classList.add('hidden')
          this.groupsTarget.insertAdjacentHTML('beforeend', html)
        })
    }

    // Set view data variable to group
    this.element.dataset.viewType = 'group_base'
    this.element.dataset.groupId = groupId
  }

  goToGroupCollection(e) {
    // Hide current view
    this.#hideCurrentView()

    const viewType = this.element.dataset.viewType

    let targetKey;
    if (viewType === 'group_base') {
      targetKey = e.currentTarget.dataset.baseCollectionKey
    } else if (viewType === 'group_collection') {
      targetKey = e.currentTarget.dataset.subCollectionKey
    }

    // Update view value (used to hide current open view)
    this.element.dataset.collectionKey = targetKey
    this.element.dataset.viewType = 'group_collection'

    this.#revealTargetCollection(targetKey)
  }

  goToGroupBaseFromMenu(e) {
    // Hide current view
    this.#hideCurrentView()

    // Show target view
    const groupId = e.currentTarget.dataset.menuLinkGroupId

    // Show group base view
    this.groupsTarget.querySelector(`[data-group-id="${groupId}"].group-view`).querySelector('.group-base-view').classList.remove('hidden')

    // Remove menu items after current view
    const elementIndex = Array.from(e.currentTarget.parentNode.children).indexOf(e.currentTarget)

    for (let i = this.menuTarget.childElementCount - 1; i > elementIndex; i--) {
      this.menuTarget.removeChild(this.menuTarget.children[i])
    }

    // Update data variable of view
    this.element.dataset.collectionKey = ''
    this.element.dataset.viewType = 'group_base'
  }

  groupCollectionFromMenu(e) {
    // Hide current view
    this.#hideCurrentView()

    const groupId = this.element.dataset.groupId
    const targetKey = e.currentTarget.dataset.menuLinkKey

    // Show target view
    this.groupsTarget.querySelector(`[data-group-id="${groupId}"].group-view`).querySelector(`[data-collection-key="${targetKey}"].group-collection`).classList.remove('hidden')

    // Remove menu items after current view
    const elementIndex = Array.from(e.currentTarget.parentNode.children).indexOf(e.currentTarget)

    for (let i = this.menuTarget.childElementCount - 1; i > elementIndex; i--) {
      this.menuTarget.removeChild(this.menuTarget.children[i])
    }

    // Update data variable of view
    this.element.dataset.collectionKey = targetKey
    this.element.dataset.viewType = 'group_collection'
  }

  /* ------------------------------BOTTOM MENU------------------------------- */
  // Show bottom menu when user selects a radio button
  showBottomMenu(e) {
    // Set the item key to the bottom menu to be used when user clicks on the import button and sends it to form
    this.bottomMenuTarget.dataset.selectedKey = e.currentTarget.value
    this.bottomMenuTarget.dataset.groupId = this.element.dataset.groupId

    this.bottomMenuTarget.classList.remove('hidden')
    this.cancelBtnTarget.classList.remove('hidden')
    this.importBtnTarget.classList.remove('hidden')
  }

  hideBottomMenu() {
    // Hide bottom menu
    this.bottomMenuTarget.classList.add('hidden')
    this.cancelBtnTarget.classList.add('hidden')
    this.importBtnTarget.classList.add('hidden')

    // Unselect radio buttons
    this.element.querySelectorAll('input[type=radio]').forEach((radio) => {
      radio.checked = false
    })
  }
  /* ------------------------------------------------------------------------ */

  /* ----------------------------PRIVATE METHODS----------------------------- */
  #hideCurrentView() {
    const viewType = this.element.dataset.viewType
    const collectionKey = this.element.dataset.collectionKey
    const groupId = this.element.dataset.groupId

    if (viewType === 'base') {
      this.element.querySelector('.base-view').classList.add('hidden')
    } else if (viewType === 'user_collection') {
      this.userLibraryTarget.querySelector(`[data-collection-key="${collectionKey}"]`).classList.add('hidden')
    } else if (viewType === 'groups') {
      this.groupsTarget.querySelector('.groups-menu-view').classList.add('hidden')
    } else if (viewType === 'group_base') {
      // Find the group then select the base view and hide it
      this.groupsTarget.querySelector(`[data-group-id="${groupId}"].group-view`).querySelector('.group-base-view').classList.add('hidden')
    } else if (viewType === 'group_collection') {
      // Find the group then select the collection view and hide it
      this.groupsTarget.querySelector(`[data-group-id="${groupId}"].group-view`).querySelector(`[data-collection-key="${collectionKey}"].group-collection`).classList.add('hidden')
    }

    // Hide bottom menu
    this.hideBottomMenu()
  }

  // Method for revealing a collection view and updating the menu
  #revealTargetCollection(key) {
    this.#setZoteroHomeButton()

    const viewType = this.element.dataset.viewType

    let elementToShow;
    let controllerMethod;
    if (viewType === 'user_collection'){
      // Find target collection view in user library
      elementToShow = this.userLibraryTarget.querySelector(`[data-collection-key="${key}"]`)
      controllerMethod = 'userCollectionFromMenu'

    } else if (viewType === 'group_collection') {
      // Find target collection view in group library
      const currentGroupId = this.element.dataset.groupId
      elementToShow = this.groupsTarget.querySelector(`[data-group-id="${currentGroupId}"].group-view`).querySelector(`[data-collection-key="${key}"].group-collection`)
      controllerMethod = 'groupCollectionFromMenu'
    }

    // Add the current collection name to the top menu
    this.menuTarget.insertAdjacentHTML('beforeend',
      `<div class="zotero-logo-text" data-menu-link-key="${key}" data-action="click->zotero-documents#${controllerMethod}">
         <button type="button" style="text-decoration:underline;">${elementToShow.dataset.name}</button>
       </div>`)
    // Reveal element
    elementToShow.classList.remove('hidden')
  }

  #setZoteroHomeButton() {
    // Underline Zotero and add event listener to go to base view
    this.zoteroTarget.style.textDecoration = 'underline'
    this.zoteroTarget.dataset.action = 'click->zotero-documents#goToBaseView'
  }
  /* ------------------------------------------------------------------------ */
}
