// SENTRY CONFIG
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.SENTRY_DSN || '',
  // Set tracesSampleRate to 1.0 to capture 100'
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.001,
})

// get user id from body data-user-id attribute
const userId = document.querySelector('body').getAttribute('data-user-id')

Sentry.setUser({ id: userId })

// ----------------------------------------------

// Initialize mixpanel
// eslint-disable-next-line no-undef
var mixpanel = require('mixpanel-browser')
// eslint-disable-next-line no-undef
mixpanel.init(`${process.env.MIXPANEL_API_KEY}`)

export { mixpanel }

// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

// Not sure if this will be needed
// import "rails_admin/src/rails_admin/base";
// import "../stylesheets/rails_admin.scss";

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';
import { textSelect } from './components/text_selector'
import { mailAnimation } from './components/mail_confirm_animation'
import { audioPlayer } from './components/audio_player'
import { awarenessNotes } from './components/awareness_notes'
import { swipe } from './components/swipe'

textSelect()
mailAnimation()
audioPlayer()
awarenessNotes()
swipe()
// newPayment()
// onboardingModal()
