import { Controller } from '@hotwired/stimulus'

// TODO: this was copied from the "slideshow" controller. That one has some
// hardcoded values (like number of slides), based on open access sign up modal
// which made it hard to reuse. Eventually this controller should be used for
// that as well, and we should delete the "slideshow" controller.

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ['slide', 'dot']

  connect() {
    this.index = 0
    this.size = this.slideTargets.length
    this.armDots()
    this.slideTo(this.index)
  }

  next() {
    if (this.index + 1 === this.size) {
      this.index = 0
    } else {
      this.index++
    }

    this.slideTo(this.index)
  }

  previous() {
    if (this.index === 0) {
      this.index = this.size - 1
    } else {
      this.index--
    }

    this.slideTo(this.index)
  }

  armDots() {
    this.dotTargets.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        this.index = index
        this.slideTo(index)
      })
    })
  }

  slideTo(position) {
    this.slideTargets.forEach((slide) => {
      slide.classList.add('hidden')
    })

    this.slideTargets[position].classList.remove('hidden')

    this.dotTargets.forEach(dot => {
      dot.classList.add('opacity-50')
    })

    this.dotTargets[position].classList.remove('opacity-50')
  }
}
